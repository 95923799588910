/*****************
	Mixins
*****************/

@mixin font-regular {
	font-family: Poppins Regular;
}

@mixin font-bold {
	font-family: Poppins Bold;
}

@mixin font-semibold {
	font-family: Poppins SemiBold;
}

@mixin font-medium {
	font-family: Poppins Medium;
}

@mixin font-light {
	font-family: Poppins Light;
}

@mixin default-shadow {
	box-shadow: 0px 3px 6px 0px #00000029;
}

@mixin default-border {
	border-radius: 4px;
}

@mixin transition($value) {
	-webkit-transition: $value;
	-moz-transition: $value;
	-ms-transition: $value;
	-o-transition: $value;
	transition: $value;
  }

@mixin placeholder($color) {
	&::-webkit-input-placeholder {
		/* Chrome/Opera/Safari */
		color: $color;
	}
	&:-moz-placeholder {
		/* Firefox 18- */
		opacity: 1;
		color: $color;
	}
	&::-moz-placeholder {
		/* Firefox 19+ */
		opacity: 1;
		color: $color;
	}
	&:-ms-input-placeholder {
		/* IE 10+ */
		color: $color;
	}
}

@mixin user-select($value) {
	// for android chrome
	-webkit-tap-highlight-color: transparent;
	-webkit-touch-callout: $value;
    -khtml-user-select: $value;
	-webkit-user-select: $value;
    -moz-user-select: $value;
    -ms-user-select: $value;
    user-select: $value;
}

@mixin keyframes($value) {
	@-webkit-keyframes #{$value} {
		@content;
	}
	@-moz-keyframes #{$value} {
		@content;
	}
	@-ms-keyframes #{$value} {
		@content;
	}
	@keyframes #{$value} {
		@content;
	}
}
/*******************
	Utility Classes
*******************/
.btn {
	cursor: pointer !important;
	border: none;
}

.error-block {
	text-align: center;
	padding: 0.8em;
	color: $error-text-color;
	font-size: 0.7em;

	&.big {
		font-weight: bold;
		font-size: 1.3em;
	}
}

.responsive-image {
	height: 100%;
	min-width: 100%;
	object-fit: cover;
}

.empty-message {
	color: #7b7c81;
	font-weight: bold;
	font-size: 1.3em;
	text-align: center;
}

.bold {
	font-weight: bold;
}

.hide {
	display: none !important;
}

.left-align {
	text-align: left;
}

.right-align {
	text-align: right;
}

.center,
.center-align {
	text-align: center;
}

.left {
	float: left !important;
}

.right {
	float: right !important;
}

img.responsive-img,
video.responsive-video {
	max-width: 100%;
	height: auto;
}

.center-block {
	display: block;
	margin-left: auto;
	margin-right: auto;
}

.no-select,
input[type="range"],
input[type="range"] + .thumb {
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.circle {
	border-radius: 50%;
}

.truncate {
	display: block;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.no-padding {
	padding: 0 !important;
}

.no-margin {
	margin: 0 !important;
}

.clearfix {
	clear: both;
}

.scrollable {
	overflow-y: auto;
}

.clickable {
	cursor: pointer;
}

.fly-component-shadow {
	box-shadow: 0px 18px 73px 0px rgba(24, 24, 25, 0.22);
}

.scale-transition {
	-webkit-transition: -webkit-transform 0.3s cubic-bezier(0.53, 0.01, 0.36, 1.63) !important;
	transition: -webkit-transform 0.3s cubic-bezier(0.53, 0.01, 0.36, 1.63) !important;
	transition: transform 0.3s cubic-bezier(0.53, 0.01, 0.36, 1.63) !important;
	transition: transform 0.3s cubic-bezier(0.53, 0.01, 0.36, 1.63),
		-webkit-transform 0.3s cubic-bezier(0.53, 0.01, 0.36, 1.63) !important;
}

.scale-transition.scale-out {
	-webkit-transform: scale(0);
	transform: scale(0);
	-webkit-transition: -webkit-transform 0.2s !important;
	transition: -webkit-transform 0.2s !important;
	transition: transform 0.2s !important;
	transition: transform 0.2s, -webkit-transform 0.2s !important;
}

.scale-transition.scale-in {
	-webkit-transform: scale(1);
	transform: scale(1);
}
