$layout-icon-inactive-color: $divider-color;
$layout-icon-active-color: $primary-color;
$layout-icon-bg-color: white;

.project-setting-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 70px;
    padding: 0 2.3em;
    background-color: white;
    box-shadow: 0px 0px 4px 0px #00000029;
    position: relative; // use to show shadow over sibling div

    .project-setting-item {
        display: flex;
        align-items: center;
    }

    .chain-selection-dropdown {
        min-width: 18em;
        margin-right: 1em;

        .overlay {
            position: fixed;
            left: 0; right: 0;
            top: 0; bottom: 0;
            z-index: 1;
        }

        .textbox {
            padding-right: 2.5em;
            text-align: center;
        }

        .dropdown-wrapper {
            width: 25em;
            top: 4.5em;
        }

        .icon-wrapper {
            right: 0;
            left: unset;
            top: 1.2em;
            height: 2.5em;
            width: 2.5em;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    .project-setting-button {
        width: 12em;
        margin-left: 1em;
    }

    .select-activity {
        min-width: 18em;
        margin-left: 1em;
    }

    .settings-icon {
        width: 2.3em;
        height: 2.3em;
        background-color: white;
        margin: 0 4px;
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        box-shadow: 0px 1px 1px 0px #0000004d;

        svg {
            display: block;
            fill: $light-grey-color;
            transition: all 0.2s linear;
        }

        .text {
            @include font-semibold;
            @include user-select(none);
            color: $light-grey-color;
            font-size: 110%;
            transition: color 0.2s linear;
        }

        &.active {
            svg {
                fill: $primary-color;
            }
            .text {
                color: $primary-color;
            }
        }
    }

    .layout-icon {
        background-color: $layout-icon-bg-color;
        height: 30px;
        width: 42px;
        padding: 0px 6px;
        border-radius: 4px;
        margin: 0 3px;
        box-shadow: 0px 0px 3px 0px #00000029;
        cursor: pointer;
        
        .halfcard-icon {
            display: inline-block;
            vertical-align: middle;
            height : 18px;
            width: 14px;
            margin: 6px 0px;
            position: relative;
            perspective: 18px;

            &::before {
                content: '';
                position: absolute;
                height : 18px;
                width: 14px;
                top: 0;
                left: 0;
                background-color: $layout-icon-inactive-color;
                -webkit-transform-style: preserve-3d;
                -webkit-transform: rotateX(8deg);
                transform-style: preserve-3d;
                transform: rotateX(8deg);
                border-radius: 2px;
                box-shadow: inset 0px 1px 1px 0px rgba(0,0,0,.2), inset 0px -1px 1px 0px rgba(255,255,255,.6);
                transition: 0.1s all ease-in;
            }

            &:first-child {
                margin-right: 2px;
            }
        }

        .fullcard-icon {
            background-color: $layout-icon-inactive-color;
            width : 28px;
            height: 8px;
            margin: 0 1px;
            border-radius: 7px;
            box-shadow: inset 0px 1px 1px 0px rgba(0,0,0,.2), inset 0px -1px 1px 0px rgba(255,255,255,.6);
            transition: 0.1s all ease-in;

            &:first-child {
                margin-bottom: 4px;
                margin-top: 5px;
            }
        }

        &.active {

            .fullcard-icon {
                background-color: $layout-icon-active-color;
            }
            .halfcard-icon {
                &::before {
                    background-color: $layout-icon-active-color;
                    -webkit-transform: rotateX(-8deg);
                    transform: rotateX(-8deg);
                    box-shadow: inset 0px 1px 1px 0px rgba(0,0,0,.2), inset 0px -1px 1px 0px rgba(255,255,255,.6);
                }
            }
        }
    }

    &.medium-screen {
        justify-content: flex-end;

        .setting-btn {
            background-color: white;
            svg {
                fill: $dark-grey-color;
                width: 1.7em;
                height: 1.7em;
            }
        }

        .plus-icon {
            width: 1.5em;
            height: 1.5em;
            
            line {
                stroke: white;
            }
        }

        .rounded-btn {
            @include default-border;
            outline: none;

            svg {
                display: block;
                margin: 0 auto;
            }
        }
    }

    // popup form
    .setting-form-wrapper {

        .chain-selection-dropdown {
            min-width: 100%;
            margin-right: 0;
        }

        .form-info-box {
            justify-content: center;

            .form-info-box-label {
                min-width: 8em;
                color: $primary-font-color;
                text-align: left;
                margin-right: 2em;
            }
        }

        .select-activity {
            margin-left: 0px;
        }

        .setting-action-wrapper {
            display: flex;
            align-items: center;
        }
    }
}