.react-select-container {
    .react-select__control {
        border: none;
        border-radius: 0.3rem;
        font-size: 1em;
        padding: 0 1em;
        background-color: #f7f7f7;
        color: $primary-font-color;
        box-shadow: 0px 0px 1px 0px #00000029;
        padding-right: 0px;

        .react-select__value-container {
            padding: 0;
            
            & > div:last-child {
                margin: 0;
                padding: 0;
            }

            input {
                box-shadow: none;
            }
        }
    }

    // override, otherwise label show in menu
    .react-select__menu {
        z-index: 3;
    }
}

.select-white-bg {
    .react-select__control {
        background-color: #fff;
    }
}