#project-details {
    .pd-quality-wrapper {
        display: flex;
        overflow-x: scroll;
        width: 100%;
        padding: 0 1.3em;
        padding-top: 15px;

        .pd-quality-list {
            flex: 1;

            .pd-checklist-wrapper {
                padding: 1em;

                @media #{$small-and-down} {
                    padding-bottom: 100px;
                }

                .pd-checklist-row {

                    .pd-checklist-name-wrapper {
                        color: $primary-font-color;
                        font-size: 0.98em;

                        & > div {
                            display: inline-block;
                            vertical-align: top;
                        }

                        .pd-checklist-name-sub {
                            color: $light-grey-color;
                            font-size: 0.8em;
                            @include font-medium;
                        }

                        span {
                            color: $light-grey-color;
                            font-size: 0.86em;
                        }
                    }

                    .pd-chl-img-wrapper {
                        margin: 1.8em 0;

                        .quality-img-block {
                            width: 120px;
                            height: 120px;
                            cursor: pointer;
                            margin-right: 28px;
                            margin-bottom: 28px;
                            display: inline-block;
                            vertical-align: top;
                            overflow: hidden;

                            @media #{$small-and-down} {
                                width: 100px;
                                height: 100px;
                                cursor: pointer;
                                margin-right: 12px;
                                margin-bottom: 12px;
                            }

                            .add-new {
                                background-color: $white;
                                border: 1px dashed $dark-grey-color;
                                width: 120px;
                                height: 120px;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                flex-direction: column;

                                .plus-icon {
                                    height: 40px;
                                    width: 40px;
                                }

                                .text {
                                    color: $dark-grey-color;
                                    font-size: 0.88em;
                                }
                            }

                            img {
                                height: 100%;
                                width: 100%;
                                object-fit: contain;
                            }
                        }
                    }

                    .divider {
                        height: 3px;
                        background-image: linear-gradient(to right, rgb(185, 185, 185) 50%, rgba(255, 255, 255, 0) 0%);
                        background-position: bottom;
                        background-size: 28px 1px;
                        background-repeat: repeat-x;
                        margin-bottom: 1.8em;
                    }

                    &:last-child {
                        .divider {
                            height: 0px;
                        }
                    }

                    .pd-checklist-action-row {
                        padding-top: 1.1em;

                        .action-btn {
                            display: inline-block;
                            vertical-align: bottom;
                            color: $white;
                            font-size: 0.74em;
                            font-weight: normal;
                            background-color: $primary-color;
                            box-shadow: 0px 3px 6px 0px #00000029;
                            padding: 3px 10px;
                            border-radius: 4px;
                            margin-left: 1.1em;
                            cursor: pointer;
                        }

                        .add-action {
                            background-color: $white;
                            color: $primary-color;
                            margin-left: 0em;
                        }
                    }
                }
            }
        }
    }
}
