#user-list-page {

    .user-list-wrapper {
        display: flex;
        margin-top: 2em;
        overflow-x: scroll;
        width: 100%;

        .user-list-container {
            flex: 1;
            padding: 8px;
            padding-bottom: 0;
            transition: height .2s linear;

            .flex-wrapper {
                flex-wrap: wrap;
                justify-content: space-around;
            }
        }
    }

    .user-block {
        border-radius: 4px;
        width: 350px;
        height: 112px;
        background-color: $white;
        position: relative;
        z-index: 0;
        overflow: hidden;
        margin-bottom: 30px;
        margin-right: 30px;
        box-shadow: 0px 1px 8px 2px rgba(0, 0, 0, 0.14);
        cursor: pointer;

        .user-edit {
            position: absolute;
            top: 0;
            right: 0;
            color: #b9b9b9;
            width: 34px;
            height: 34px;
            line-height: 34px;
            text-align: center;
            transition: all 0.2s linear;

            &:hover {
                background-color: #efefef;
                border-radius: 4px;
            }

            .edit-icon {
                height: 14px;
                width: 14px;
            }
        }

        .user-data {
            height: inherit;

            &::after {
                clear: both;
                content: "";
                display: table;
            }

            .common-image {
                width: 100px;
                height: 100%;
                float: left;
                object-fit: cover;
            }

            .common-letter-wrapper {
                float: left;
                width: 100px;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: #eac922;

                .common-letter {
                    font-size: 2em;
                    font-weight: bold;
                    text-transform: capitalize;
                    color: $white;
                }
            }

            .user-content {
                float: left;
                width: calc(100% - 100px);
                padding: 0.4em 0.8em;
                overflow: hidden;
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                position: relative;

                &::after {
                    position: absolute;
                    content: "";
                    width: 100%;
                    height: 0px;
                    bottom: 0;
                    left: 0;
                    transition: all 0.2s linear;
                }

                &.user-inactive {
                    &::after {
                        background-color: $error-color;
                        height: 5px;
                    }
                }

                .switch-wrapper {
                    text-align: right;
                    height: 16px;
                }

                .title {
                    font-size: 1.2em;
                    padding: 0 1.4em 0.3em 0;
                    color: $primary-font-color;
                }

                .u-email {
                    font-size: 0.76em;
                    color: $dark-grey-color;
                }

                .u-reset {
                    color: $dark-grey-color;
                    font-size: 0.76em;
                    padding-top: 1em;
                }
            }
        }
    }
}
