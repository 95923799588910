@import "./constant";
@import "./layout";
@import "./utils";

@import "./forms/form";
@import "./forms/buttons";
@import "./forms/inline_tabs";
@import "./common/notification";
@import "./common/navbar";
@import "./common/sidebar_nav";
@import "./common/common_page_layout";
@import "./common/checkbox";
@import "./common/react_super_picker";
@import "./common/react_select";
@import "./common/loader";
@import "./common/auth_page";
@import "./common/page_not_found";

@import "./project/project_details";
@import "./project/project_settings";
@import "./project/project_banner";
@import "./project/project_planning_list";
@import "./project/project_planning_details";
@import "./project/project_filter";
@import "./project/project_list";
@import "./project/planning_list";
@import "./project/project_drawing_list";
@import "./project/project_quality_list";
@import "./project/project_quality_popup";
@import "./project/project_quality_details_popup";
@import "./project/project_quality_gallery";

@import "./planning/planning_form";
@import "./planning/planning_checklist_form";

@import "./admin/admin_home_page";
@import "./admin/user_list";
@import "./admin/user_form";
@import "./admin/user_toggle_switch";
@import "./admin/holiday_list";
@import "./admin/activity_list";
@import "./admin/project_form";
@import "./admin/project_unit_list";
@import "./admin/project_permission_list";
@import "./admin/reports";
@import "./admin/reports_quality";
@import "./admin/reports_engineers";

@import "./common/profile_page";
@import "./common/dashboard_page";
@import "./common/chain_selection_dropdown";
@import "./common/privacy_policy";

@import "./demo";
@import "./demo_dummy";
@import "./demo_dummy_plot_card";

@import "~react-image-gallery/styles/scss/image-gallery.scss";

/**
Z indexs summery
	nav - 4
	page-model - 5
	notification - 100
**/

* {
  box-sizing: border-box;
}

a,
a:link,
a:visited,
a:focus,
a:hover,
a:active {
  text-decoration: none;
  color: inherit;
}

html,
body {
  font-family: Poppins Regular, Geneva, Tahoma, Verdana, sans-serif;
  font-size: 14px;
  padding: 0px;
  margin: 0px;
  height: 100%;
  width: 100%;
  background-color: $main-bg-color;
}

#root {
  height: 100%;
  width: 100%;
}
