#project-details{
	// planning card ---- > card details popup design
	.pd-pln-col-card-details {
		width: 40em;
		padding: 2em 4em;

		@media screen and (max-width : #{$small-screen-up}) {
			width: 22rem;
			margin: 0 auto;
			padding: 2rem;
		}

		.pd-details-action {

			.multi-action {
				text-align: center;
			}

			.remark-wrapper {
				& > .form-group {
					display: inline-block;
					min-width: 15.6em;
				}
			}

			.rounded-btn {
				.check-icon {
					display: block;
					margin: 0 auto;
					fill: white;
				}

				.close-icon {
					display: block;
					margin: 0 auto;
					width: 18px;
					height: 18px;
					fill: $primary-color;
				}
			}

			.btn {
				max-width: 11em;
				margin: 1em;

				.icon {
					padding: 0 4px;

					&.icon-pause, &.icon-play {
						&::before {
							margin-bottom: 2px;
						}
					}
				}
			}
		}

		.pd-details-summery {
			padding-top: 0.8em;
			color: $dark-grey-color;

			span {
				color: $primary-font-color;
			}
		}

		.pd-details-desc {
			color: $dark-grey-color;
		}

		.schedule-title {
			color: $primary-font-color;
			padding-bottom: 8px;
			border-bottom: 1px solid $input-search-icon-color;
		}

		.pd-schedule-list-wrapper {
			width: 100%;

			.pd-schedule-list-block {

				.pd-schedule-list-block-content {
					width: 90%;

					.cell-pill-row {
						min-height: 2em;
						line-height: 2em;

						&.date {
							color: $dark-grey-color;
							@include font-medium();
						}
					}
					.remark {
						min-height: 2em;
						color: $light-grey-color;
						padding: .8em;
					}
				}

				.pd-schedule-list-block-indicator {
					&.on {
						background-color: #10C41C;
					}
	
					&.off {
						background-color: #FF0000;
					}
				}
			}
		}
	}
}