$sidebar-bg-color: #E8E8E8;
$sidebar-highlight-color: $primary-color;
$sidebar-block-icon-size: 22px;

.sidebar-column-wrapper {
	width: $sidebar-width;
	height: 100%;
	overflow: auto;
	position: fixed;
	left: 0;
	top: $navbar-height;
	bottom: 0;
    background-color: $sidebar-bg-color;
    box-shadow: 0px 2px 3px 0px #a6a6a6;

    .sidebar-menu {
		width: 100%;
		margin: auto 0;
		
		.sidebar-block {
			width: 100%;
			display: block;
			text-align: center;
			padding: 12px 10px;
			position: relative;
			transition: all .2s linear;
			color: $primary-font-color;
			border-right: 3px solid transparent;

			.sb-block-icon {
				svg {
					height: auto;
					width: $sidebar-block-icon-size;
				}
			}

			.sb-block-title {
				padding: 4px 0;
				font-size: 0.9rem;
                @include font-medium;
                transition: color .2s;
			}

			&:hover, &.active {
				border-right-color: $sidebar-highlight-color;
                .sb-block-title {
                    color: $sidebar-highlight-color;
                }
			}
		}
	}

	@media #{$small-and-down} {
		width: 100%;
		height: $bottom-navbar-height;
		top: unset !important;
		z-index: 1;

		.sidebar-menu {
			display: flex;
			align-items: center;
			
			.sidebar-block {
				width: auto;
				flex: 1;
				height: $bottom-navbar-height;
				padding: 0;
				display: flex;
				align-items: center;
				justify-content: center;
				flex-direction: column;
				border-top: 3px solid transparent;
				border-right: none;

				.sb-block-title {
					padding: 0;
					display: none;
				}

				&:hover, &.active {
					border-top-color: $sidebar-highlight-color;
				}
			}
		}
	}
}

.sidebar-aside-wrapper {
    padding-left: $sidebar-width;

	@media #{$small-and-down} {
		padding-left: 0;
	}
}

// used icon css update here
.sidebar-column-wrapper {

	.sidebar-block.active, .sidebar-block:hover {
		.sb-block-icon {

			.drawing-svg-icon {
				.drawing-svg-icon-path1 {
					fill: url(#New_Gradient_Swatch_4) !important;
				}
				.drawing-svg-icon-path2 {
					fill: url(#New_Gradient_Swatch_4-2) !important;
				}
			}

			.planning-svg-icon {
				.planning-svg-icon-path {
					fill: url(#planningicon-gradient-active);
				}
			}

			.quality-svg-icon {
				.quality-svg-icon-path {
					fill: url(#qualityicon-gradient-active);
				}
			}
		}
	}

	.planning-svg-icon {
		width: 19px !important;

		.planning-svg-icon-path {
			fill: url(#planningicon-gradient);
		}
	}

	.quality-svg-icon {
		width: 26px !important;

		.quality-svg-icon-path {
			fill: url(#qualityicon-gradient);
		}
	}

	.drawing-svg-icon {
		width: 26px !important;
	}
}