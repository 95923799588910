$filter-sidebar-width: 250px;
$sidebar-mobile-width: 40px;

#project-details {
	.page-content-wrapper {
		.page-content-sidebar-wrapper {
			position: relative;
			width: $filter-sidebar-width;
			background-color: $white;
			box-shadow: 0px 3px 6px 0px #00000029;

			&::after {
				content: "";
				clear: both;
				display: table;
			}

			.page-content-sidebar-abs-wrapper {
				position: absolute;
				left: 0;
				right: 0;
				top: 0;
				bottom: 0;
				z-index: 2;

				.page-content-sidebar {
					width: $filter-sidebar-width;
					height: 100%;
					overflow-y: auto;
					padding-top: 2.3em;
					padding-left: 3em;
					padding-right: 1.4em;
					padding-bottom: 0;

					.clear-wrapper {
						text-align: center;
						margin-left: -1.6em;
						padding-bottom: 0.5em;

						.clear-filter {
							display: inline-block;
							color: $dark-grey-color;
							cursor: pointer;
							opacity: 0;
							transition: all 0.1s linear;
							text-decoration: underline;

							&.show {
								opacity: 1;
							}
						}
					}

					.title {
						@include font-bold;
						font-size: 1.03em;
						color: $dark-grey-color;
						padding: 0.5em 0 0.5em 0.6em;
					}

					// Project filter ---- > filter block design
					.filter-bot-padding {
						padding-bottom: 1.5em;
					}

					.filter-block {
						padding-bottom: 1.5em;

						&:last-child {
							padding-bottom: 0.8em;
						}

						.checkbox-container {
							input {
								&:checked {
									& ~ .checkmark {
										&::after {
											border-width: 0px 1px 1px 0;
										}

										.check {
											background-color: $dark-blue-color;
											border-color: $dark-blue-color;
										}
									}

									& ~ .checkbox-label {
										font-weight: bold;
									}
								}
							}
						}

						.filter-block-main {
							padding-bottom: 0.4em;

							.checkbox-label {
								@include font-bold;
								font-size: 1.03em;
								color: $dark-grey-color;
								padding-left: 0.5em;
							}
						}

						.filter-sub {
							padding-left: 2em;

							.checkbox-label {
								color: $dark-grey-color;
								padding-left: 0.6em;
								font-size: 0.94em;
							}
						}
					}
				}

				.mobile-sidebar-pill {
					display: none;
				}
			}
		}

		@media #{$small-and-down} {
			.page-content-sidebar-wrapper {
				width: $sidebar-mobile-width;
				box-shadow: none;

				&.sidebar-out {
					.page-content-sidebar-abs-wrapper {
						left: -$filter-sidebar-width;

						.mobile-sidebar-pill {
							.rt-text {
								.icon::before {
									transform: rotate(90deg);
								}
							}
						}
					}
				}

				&.sidebar-in {
					.page-content-sidebar-abs-wrapper {
						left: 0;

						.mobile-sidebar-pill {
							.rt-text {
								.icon::before {
									transform: rotate(-90deg);
								}
							}
						}
					}
				}

				.page-content-sidebar-abs-wrapper {
					width: $filter-sidebar-width + $sidebar-mobile-width;
					background-color: $white;
					box-shadow: 0px 3px 6px 0px #00000029;
					transition: all 0.2s linear;

					.mobile-sidebar-pill {
						display: flex;
						align-items: center;
						justify-content: center;
						flex-direction: column;
						width: 40px;
						height: 100%;
						cursor: pointer;
						border-left: 1px solid $divider-color;

						.rt-text {
							transform: rotate(-90deg);
							white-space: nowrap;
							color: $dark-blue-color;

							.icon {
								color: $primary-font-color;
								font-size: 0.8em;
								padding-left: 0.6em;

								&::before {
									transition: all 0.2s linear;
								}
							}
						}
					}
				}
			}
		}
	}
}
