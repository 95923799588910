.planning-form {
    .page-content-wrapper {
        display: block !important;
        margin: 0 auto;
        width: 96%;
        max-width: 1500px;
    }
    .pl-form-planning-col {
        display: inline-block;
        vertical-align: top;
        width: 75%;
        overflow: auto;

        .pl-form-wrapper {
            background: white;
            padding: 0em 1.5em 1.5em 1.5em;
            margin: 2em;
            margin-left: 0;
            box-shadow: 0px 3px 6px 0px #00000029;

            .planning-select-content {
                margin-top: 6px;
                text-align: left;

                .qs-heading {
                    font-size: 0.86em;
                    color: $primary-font-color;
                    line-height: 1.1;
                }

                .qs-text {
                    font-size: 0.7em;
                    color: darken($dark-grey-color, 15%);
                }
            }

            .react-select__option--is-selected {
                .planning-select-content {
                    .qs-heading {
                        color: darken($white, 2%);
                    }
    
                    .qs-text {
                        color: darken($white, 8%);
                    }
                }
            }
        }

        h2 {
            text-align: center;
            margin: 0px;
            margin-bottom: 1em;
            padding: 0.5em;
            border-bottom: $cool-grey-color 1px solid;
        }
    }

    .pl-form-holiday-col {
        display: inline-block;
        vertical-align: top;
        width: 25%;
        border-left: dashed 1px silver;
        overflow: auto;
        padding: 0 6px;

        .title {
            text-align: center;
        }

        .holiday-list-wrapper {
            padding-top: 1em;
            margin-right: 0;
            flex-direction: column;

            .holiday-block {
                margin: 10px auto;
                width: 100%;
                max-width: 300px;
            }

            .empty-wrapper {
                color: $dark-grey-color;
                font-size: 1.2em;
                padding-top: 0.8em;
                text-align: center;
            }
        }
    }

    .pl-form-action-btn {
        text-align: right;
        margin-top: 10px;

        .btn {
            max-width: 200px;
            margin-right: 20px;
            
            &:last-child {
                margin-right: 0px;
            }
        }

        &.checklist-btn {
            margin: 1.4em 0;
        }

        // &.submit {
        //     margin-left: auto;
        // }
    }

    .checklist-row {
        .checklist-remove-col {
            height: 4em;

            .cl-remove-text {
                color: rgba($color: $primary-font-color, $alpha: 0.75);
                display: inline-block;
                text-decoration: underline;
                cursor: pointer;
                padding-top: 1.8em;
            }
        }
    }

    .subAct-row {
        margin-bottom: 20px;

        .subAct-row-pad {
            padding: 0 0.75rem;
        }

        .subAct-title {
            color: $primary-font-color;
            font-weight: bolder;
            padding: 0 1em;
            padding-bottom: 0.6em;
        }

        .subAct-check {
            padding-top: 1.4em;

            &.enable {
                height: 4em;

                .checkbox-container {
                    margin: 0 auto;
                }
            }

            &.not-enable {
                margin-top: -1em;
            }
        }
    }
}

// currently for demo
.estimat-input {
    .button {
        display: inline-block;
        vertical-align: top;
        line-height: 36px;
        height: 36px;
        width: 36px;
        border-radius: 0.3rem;
        text-align: center;
        font-size: 1.2em;
        margin-top: 1em;
        background: $cool-grey-color;
        cursor: pointer;
        color: white;
    }

    .form-group {
        display: inline-block;
        vertical-align: top;
        width: calc(100% - 80px);

        input {
            &::-webkit-outer-spin-button,
            &::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0;
            }
        }

        input[type="number"] {
            -moz-appearance: textfield;
        }
    }
}
