#project-details {
    .pd-drawing-list-wrapper {
        display: flex;
        overflow-x: scroll;
        padding: 0 1.3em;
        padding-top: 15px;

        @media #{$extra-large-and-up} {
            min-width: 1000px;
        }

        .pd-drawing-list {
            text-align: center;
            width: 100%;

            .pd-drawing-block {
                display: inline-block;
                vertical-align: top;
                text-align: left;
                position: relative;
                box-shadow: 0px 3px 6px 0px #00000029;
                border-radius: 4px;
                background-color: $white;
                width: 280px;
                padding: 0.7em;
                margin: 0 1em 1em 0;

                .pd-drawing-edit {
                    position: absolute;
                    top: 0;
                    right: 0;
                    color: #b9b9b9;
                    width: 34px;
                    height: 34px;
                    line-height: 34px;
                    text-align: center;
                    transition: all 0.2s linear;
                    cursor: pointer;

                    &:hover {
                        background-color: #efefef;
                        border-radius: 4px;
                    }

                    .edit-icon {
                        height: 14px;
                        width: 14px;
                    }
                }

                .pd-drawing-title {
                    @include font-bold;
                    font-size: 0.9em;
                    min-height: 2.3em;
                    color: $primary-font-color;
                    padding-bottom: 0.8em;
                    padding-right: 30px;
                }

                .pd-drawing-desc {
                    color: rgba($color: $primary-font-color, $alpha: 0.65);
                    font-size: 0.76em;
                    font-weight: normal;
                    padding-bottom: 1em;
                    // min-height: 3.5em;
                }

                .pd-drawing-action {
                    padding-top: 1em;

                    .action-btn {
                        display: inline-block;
                        color: $white;
                        font-size: 0.74em;
                        font-weight: normal;
                        background-color: $primary-color;
                        box-shadow: 0px 3px 6px 0px #00000029;
                        padding: 3px 10px;
                        border-radius: 4px;
                        cursor: pointer;
                    }
                }

                @media #{$small-and-down} {
                    &:last-child {
                        margin-bottom: $bottom-navbar-height + 20px;
                    }
                }
            }
        }

        // popup
        .pd-drawing-popup {
            width: 30em;
            padding: 2rem 4rem;

            .title {
                padding-bottom: 1em;
            }

            .drawing-img-upload-wrapper {
                display: table;
                padding: 1em 0;

                .drawing-img-upload-col {
                    display: table-cell;
                    vertical-align: middle;
                    width: 8em;

                    .drawing-img-upload-block {
                        width: 8em;
                        height: 8em;
                        background-color: #f5f5f5;
                        border: 1px dashed #bcbbbb;
                        border-radius: 3px;
                        position: relative;
                        overflow: hidden;

                        .drawing-img-upload-content {
                            width: 100%;
                            height: 100%;
                            position: absolute;
                            z-index: 0;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                        }

                        input {
                            width: 100%;
                            height: 100%;
                            position: absolute;
                            left: 0;
                            top: 0;
                            z-index: 1;
                            opacity: 0;
                            cursor: pointer;
                        }

                        img {
                            width: 8em;
                            height: 8em;
                            object-fit: cover;
                            position: relative;
                        }
                    }
                }

                .drawing-img-info-col {
                    display: table-cell;
                    vertical-align: middle;
                    text-align: left;
                    padding-left: 0.86em;

                    .heading {
                        color: $dark-grey-color;
                        text-decoration: underline;
                    }

                    .desc {
                        color: #989797;
                        font-size: 0.76em;
                    }
                }
            }

            .drawing-action-wrapper {
                .btn {
                    margin-top: 2em;
                    width: calc(50% - 8px);

                    &.primary-btn {
                        background-color: #6054a8;
                    }

                    &:first-child {
                        margin-right: 8px;
                    }

                    &:last-child {
                        margin-left: 8px;
                    }
                }
            }

            .drawing-img-indicator {
                text-align: left;
                margin: 0.4em 0;
                opacity: 0;
                transition: opacity 0.2s linear;

                &.show {
                    opacity: 1;
                }

                .img-name {
                    font-size: 0.84em;
                    line-height: 1.1;
                    color: #333131;
                }

                .indicator-wrap {
                    & > div {
                        display: inline-block;
                        vertical-align: middle;
                    }

                    .ind {
                        height: 0.8em;
                        width: calc(100% - 14px);
                        background-color: #00d200;
                        border-radius: 3px;
                        opacity: 0.5;
                    }
                    .remove {
                        width: 10px;
                        margin-left: 4px;
                        cursor: pointer;
                    }
                }
            }

            .react-select-container .react-select__control {
                min-height: 3.2em;
            }

            .drawing-select-content {
                margin-top: 6px;
                text-align: left;

                .qs-heading {
                    font-size: 0.86em;
                    color: $primary-font-color;
                    line-height: 1.1;
                }

                .qs-text {
                    font-size: 0.7em;
                    color: darken($dark-grey-color, 15%);
                }
            }
        }
    }
}
