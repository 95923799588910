.project-banner-content {
    margin-top: 20px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    box-shadow: 0px 3px 6px 0px #00000029;
    background-color: rgba($color: $white, $alpha: 0.85);
    border-radius: 4px;
    padding: 2.2em;
    max-width: 600px; // cap width for screen > 1500
    transition: opacity .2s linear;

    @media only screen and (min-width: $small-screen-up) and (max-width: $large-screen-up) {
        flex: 2;
        font-size: 12px;
    }

    .project-ban-content-info-col {
        flex: 3;
        display: flex;
        align-items: center;
    }

    .project-ban-content-col {
        flex: 1;
        padding: 0 0.4em;

        &:first-child {
            padding-left: 0;
        }

        &:last-child {
            padding-right: 0;
        }

        .label {
            color: $primary-font-color;
            font-size: 0.9em;
            @include font-light;
        }

        .value {
            color: $primary-font-color;
            font-size: 2.6em;
        }

        $circle-width: 10em;
        .pb-circle {
            width: $circle-width;
            height: $circle-width;
            box-shadow: 0px 3px 6px 0px #00000029;
            background-color: rgba($color: #fcfcfc, $alpha: 0.75);
            border-radius: 50%;
            position: relative;
            margin-left: auto;

            svg {
                circle {
                    transition: stroke-dashoffset 0.35s;
                    transform: rotate(-90deg);
                    transform-origin: 50% 50%;
                    stroke: $primary-color;
                    fill: transparent;
                }
            }

            .pb-circle-wrapper {
                position: absolute;
                width: 100%;
                height: 100%;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;

                .percentage {
                    color: #6054a8;
                    font-size: 2em;
                    line-height: 1.2;
                }

                .text {
                    color: $dark-blue-color;
                    font-size: 0.65em;
                    padding: 0px 3em;
                    text-align: center;
                }
            }
        }
    }
}