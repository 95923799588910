
.page-banner-wrapper {
    position: relative;
    z-index: 0;
    height: $banner-height;
    border-bottom: 1px solid rgba(112, 112, 112, 0.2);
    background-color: $cool-grey-color;
    box-shadow: inset 0px -9em 9em -9em rgba(0, 0, 0, 0.4);

    .page-banner-image {
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        background-color: $cool-grey-color;
        position: absolute;
        left: 0; right: 0;
        top: 0; bottom: 0;
        z-index: -2; // to set banner beside text
    }

    .page-banner-gradient {
        background: rgb(0,0,0);
        background: linear-gradient(180deg, rgba(0,0,0,0) 30%, rgba(0,0,0,0.32) 100%);
        position: absolute;
        left: 0; right: 0;
        top: 0; bottom: 0;
        z-index: -1; // to shadow over image
    }

    .page-header {
        padding-top: 150px; // to show title at bottom of banner
        font-size: 2.2em;
        color: $white;
    }

}

.main-page-content {
    padding-top: 40px;
    height: calc(100% - 50px);
    position: relative;
}

.page-right-actions {
    float: right;
    width: 50%;
    margin-top: -44px;
}

.page-action-wrapper {

    .global-search-container {
        width: 70%;
        display: inline-block;
        padding-right: 3em;

        .wrapper {
            position: relative;

            .icon-wrapper {
                cursor: pointer;
                height: 40px;
                width: 40px;
                position: absolute;
                right: 0;
                top: 0;
                text-align: center;
                line-height: 46px; // to display center
            }

            .search-input {
                padding-right: 40px;
            }
        }
    }

    .page-add-new {
        width: 30%;
        display: inline-block;
    }
}

.empty-page-wrapper {
    width: 70%;
    margin: 6% auto;
    text-align: center;

    img {
        max-width: 100%;
    }

    .text {
        color: $dark-grey-color;
        font-size: 2em;
        padding-top: 0.8em;
    }
}

.page-model {
    position: fixed;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
    background-color: rgba(103, 82, 82, 0.1);
    backdrop-filter: blur(4px);
	overflow-y: auto;
    z-index: 5;

    .page-model-wrapper {
        display: table;
        width: 100%;
        height: 100%;

        .page-model-cell {
            display: table-cell;
            vertical-align: middle;
            text-align: center;
        }
    }

    
    .page-model-content {
		border-radius : 12px;
        box-shadow: 0px 3px 16px 0px #0000004D; 
        background-color: $off-white;
        margin: 8% auto;
		position: relative;
        z-index: 1;

        width: 40rem;
        padding: 2rem 8rem;

        @media #{$small-and-down} {
            width: 94%;
            margin: 0 auto;
            padding: 2rem;
        }
    }

    .model-form {
        
        .title {
            font-size: 1.4em;
            text-align: center;
            padding-bottom: 1.6em;
            margin-top: -0.4em;
            color: $primary-font-color;
        }

        .btn {
            margin-top: 2.6em;
        }

        .close-icon-wrapper {
            position: absolute;
            top: 1em;
            right: 1em;
            cursor: pointer;
            width: 26px;
            height: 26px;
            display: flex;
            justify-content: center;
            align-items: center;
            transition: all .2s linear;

            &:hover {
                background-color: #efefef;
                border-radius: 4px;
            }

            .close-icon {
                position: relative;
    
                &::before {
                    position: absolute;
                    content: "";
                    border-radius: 50%;
                    height: 1px;
                    width: 22px;
                    left: -11px;
                    background-color: grey;
                    transform: rotate(-45deg);
                }
                &::after {
                    position: absolute;
                    content: "";
                    height: 1px;
                    width: 22px;
                    left: -11px;
                    background-color: grey;
                    transform: rotate(45deg);
                }
            }
        }

    }
}


@mixin back-hover-effect {
	.arrow-path {
        stroke-width: 2;
        transition: stroke-width 0.2s linear;
    }

    .arrow-bg-group {
        opacity: 0;
        transition: opacity 0.2s linear;
    }

    .arrow-border-group {
        opacity: 0;
        transition: opacity 0.2s linear;
    }

    &:hover {
        .arrow-path {
            stroke-width: 5;
        }
        .arrow-bg-group {
            opacity: 0.2;
        }
        .arrow-border-group {
            opacity: 1;
        }
    }
}

.page-back-pill {
    background-color: white;
    color: $primary-font-color;
    display: flex;
    align-items: center;
    height: $project-title-height;
    border-bottom: 1px solid $divider-color;

    .page-back-content {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 250px; // same as sidebar width

        .back-icon {
            width: 30px;
            height: 30px;

            .arrow-bg-group {
                fill: $primary-font-color;
            }
        }

        .page-back-text {
            margin-top: -2px;
            padding: 0 0.6em;
            text-decoration: none;
            transition: all 0.2s linear;
            color: $primary-font-color;
        }

        &:hover {
            .page-back-text {
                text-decoration: underline;
            }
        }
    }

    .page-pill-title {
        flex: 1;
        text-align: center;
        @include font-medium();
        font-size: 1.1em;
    }
}

.primary-bg-color {
    background-color: $primary-color !important;
}

.primary-text-color {
    color: $primary-color !important;
}