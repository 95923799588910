#project-details{
    .pd-planning-wrapper {
        display: flex;
        width: 100%;
        // margin-top: 2em;
        min-width: 1000px;
        overflow-x: scroll;
    
        .pd-planning-col {
            flex: 1;
    
            &:last-child {
                .pd-planning-col-card-wrapper {
                    background-image: none;
                }
            }
    
            .pd-planning-col-title {
                @include font-bold;
                font-size: 1.1em;
                text-align: center;
                letter-spacing: 0px;
                margin-bottom: 2.2em;
                color: $primary-font-color;
    
                span {
                    @include font-regular;
                    font-size: 0.94em;
                    letter-spacing: 0px;
                    color: $light-grey-color;
                    font-weight: normal;
                    vertical-align: middle;
                }
            }
    
            .pd-planning-col-card-wrapper {
                overflow-y: auto;
                // create dashed line btw col using this background-image
                background-image: linear-gradient(rgb(185, 185, 185) 55%, rgba(255,255,255,0) 0%);
                background-position: right;
                background-size: 1px 24px;
                background-repeat: repeat-y;
                transition: height .2s linear;
    
                // planning card ---- > card block design
    
                .pd-pln-col-card {
                    position: relative;
                    width: 80%;
                    margin: 0.9em auto;
                    box-shadow: 0px 3px 6px 0px #00000029;
                    border-radius: 4px;
                    background-color: $white;
                    max-width: 284px;
                    padding: 0.7em;
    
                    .pd-pln-col-card-title {
                        @include font-bold;
                        font-size: 0.9em;
                        color: $primary-font-color;
                        padding-bottom: 0.8em;
                    }
    
                    .pd-pln-col-card-desc {
                        color: rgba($color: $primary-font-color, $alpha: 0.65);
                        font-size: 0.76em;
                        font-weight: normal;
                        padding-bottom: 1em;
                        // min-height: 3.5em;
                    }
    
                    .pd-pln-col-card-action {
                        padding-top: 1em;
    
                        .action-btn {
                            display: inline-block;
                            color: $white;
                            font-size: 0.74em;
                            font-weight: normal;
                            background-color: $primary-color;
                            box-shadow: 0px 3px 6px 0px #00000029;
                            padding: 3px 10px;
                            border-radius: 4px;
                            cursor: pointer;
                        }
                    }
    
                    .pd-pln-col-card-status {
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        width: 100%;
                        z-index: 0;
                        pointer-events: none;
    
                        &.before {
                            .text {
                                color: rgba($color: #10C41C, $alpha: 0.5);
                            }
                            .ind {
                                background-color: #10C41C;
                            }
                        }
    
                        &.delay {
                            .text {
                                color: rgba($color: #FF0000, $alpha: 0.5);
                            }
                            .ind {
                                background-color: #FF0000;
                            }
                        }
    
                        .text {
                            text-align: right;
                            font-size: 0.71em;
                            padding: 6px;
                        }
    
                        .ind {
                            height: 5px;
                            border-bottom-left-radius: 4px;
                            border-bottom-right-radius: 4px;
                        }
                    }
                }
            }
        }
    }

    .pd-planning-list-wrapper  {
        padding: 1em;
        padding-top: 0;

        .report-page {
            overflow-y: auto;
        }
        
        .action-btn {
            display: inline-block;
            color: $white;
            font-size: 0.74em;
            font-weight: normal;
            background-color: $primary-color;
            box-shadow: 0px 3px 6px 0px #00000029;
            padding: 3px 10px;
            border-radius: 4px;
            cursor: pointer;
            min-width: 7.5em;
        }
    }
}