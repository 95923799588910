$secondary-brown: #dfd0bb;
$secondary-dark-brown: #e5bd77;

#demo-page {
  width: 100%;
  overflow: hidden;
  z-index: 1;

  .demo-control {
    position: absolute;
    right: 2em;
    top: 2em;
    z-index: 100;
  }

  .plan-img-container > img {
    width: 100%;
    object-fit: cover;
  }

  .svg-plan {
    position: absolute;

    image {
      pointer-events: none;
    }

    .svg-plot {
      cursor: pointer;
      fill: transparent;
      transition: fill 0.3s ease-in-out;

      &:hover {
        fill: #dfd0bb99;
        // fill: rgba(32, 99, 149, 0.4);
      }

      &.focused {
        stroke-width: 2;
        stroke: #943d2c;
        -webkit-filter: drop-shadow(1px 1px 4px $secondary-dark-brown);
        filter: drop-shadow(1px 1px 4px $secondary-dark-brown);
        stroke-dasharray: 1000;
        stroke-dashoffset: 1000;
        animation: dash 5s linear forwards;
      }
    }
  }
}

@mixin sidebar-btns {
  position: absolute;

  display: flex;
  cursor: pointer;
  background: $secondary-brown;
  padding: 1em;
  border-radius: 5px;
  transition: background 0.2s linear;

  &:hover {
    background: $secondary-dark-brown;
  }
}

#demo-sidebar {
  z-index: 10;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  width: 30%;
  min-width: 35em;
  padding: 10px;
  transition: transform 0.3s ease-in-out;

  .sidebar-content-wrapper {
    // background: #3b42d9aa;
    // border-radius: 2em;
    height: calc(100% - 2em);
    position: relative;

    .toggle-btn {
      @include sidebar-btns;
      left: -3em;
      top: 2em;

      &.right {
        svg {
          transform: rotate(0deg);
        }
      }

      &.left {
        svg {
          transform: rotate(180deg);
        }
      }

      & > svg {
        transition: transform 0.3s ease-in-out;
      }
    }

    .card-btn {
      @include sidebar-btns;
      left: -7em;
      top: 2em;
    }

    .content-title {
      padding: 1em;
      text-align: center;
      font-size: 2em;
      font-weight: bold;
    }

    .content {
      display: flex;
      height: 100%;
      justify-content: space-around;
      flex-direction: column;

      .content-image {
        padding: 1em;
        max-height: 300px;
        text-align: center;

        img {
          max-height: 300px;
        }
      }

      .dsc-search-bar {
        justify-content: center;

        & > div {
          display: flex;
          width: 100%;
          max-width: 500px;
        }
      }

      .dsc-dummy {
      }
    }
  }
}

.demo-sidebar1 {
  .sidebar-content-wrapper {
    background-color: #ffffff99;
    border-radius: 20px;
    box-shadow: inset 5px 5px 10px rgba(0, 0, 0, 0.1),
      inset 5px 5px 20px rgba(255, 255, 255, 0.1),
      inset -5px -5px 15px rgba(0, 0, 0, 0.2);
  }

  .content-title {
    color: #943d2c;
  }

  .dsc-search-bar {
    padding: 2em;
    display: flex;
    align-items: center;

    input {
      background-color: $secondary-brown;
      border-radius: 5px;
      border: 0;
      color: #943d2c;
      height: 45px;
      line-height: 45px;
      outline: none;
      transition: all 0.2s ease-out;
      width: 100%;
      padding: 0 1em;

      &::placeholder {
        color: darken(#943d2c, 15%);
        opacity: 1; /* Firefox */
      }

      &:-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: darken(#943d2c, 15%);
      }

      &::-ms-input-placeholder {
        /* Microsoft Edge */
        color: darken(#943d2c, 15%);
      }

      &:focus {
        background: $secondary-dark-brown;
        color: #943d2c;
        webkit-box-shadow: 0px 0px 10px 8px #943d2c55;
        -moz-box-shadow: 0px 0px 10px 8px #943d2c55;
        box-shadow: 0px 0px 10px 8px #943d2c55;
        &::placeholder {
          color: lighten(#943d2c, 15%);
          opacity: 1; /* Firefox */
        }

        &:-ms-input-placeholder {
          /* Internet Explorer 10-11 */
          color: lighten(#943d2c, 15%);
        }

        &::-ms-input-placeholder {
          /* Microsoft Edge */
          color: lighten(#943d2c, 15%);
        }
      }
    }

    button {
      background: #943d2c;
      padding: 0.8em;
      border-radius: 5px;
      outline: 0;
      margin-left: 0.5em;
      border-width: 0;
      cursor: pointer;
      transition: all 0.2s linear;

      &:hover {
        -webkit-box-shadow: 0px 0px 10px 4px rgba(0, 0, 0, 0.4);
        -moz-box-shadow: 0px 0px 10px 4px rgba(0, 0, 0, 0.4);
        box-shadow: 0px 0px 10px 4px rgba(0, 0, 0, 0.4);
      }

      & path {
        fill: white;
      }
    }
  }
}

@keyframes dash {
  to {
    stroke-dashoffset: 0;
  }
}
