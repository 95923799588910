.report-page {
    .quality-container {
		background: white;
		margin: 1em 0;
		padding: 2em;

		.quality-container-info {
			display: table;
			width: 100%;

			.quality-container-title {
				display: table-cell;
				vertical-align: middle;
				@include font-medium;
				font-size: 1.2em;
				color: $primary-font-color;
			}

			.quality-container-check {
				display: table-cell;
				vertical-align: middle;
				width: 45px;

				.report-checkbox-wrapper {
					margin: 0 auto;
				}
			}
		}

		.divider {
			border-bottom: 1px solid $input-search-icon-color;
			margin: 0.6em 0;
		}

		.quality-check-images-wrapper {
			.check-img-block {
				width: 234px;
				margin-bottom: 16px;
				margin-right: 16px;
				display: inline-block;
				vertical-align: top;
				text-align: center;
				padding: 6px;

				.check-img-wrapper {
					width: 222px;
					height: 222px;
					position: relative;
					cursor: pointer;

					.check-image {
						width: 222px;
						height: 222px;
						object-fit: contain;
					}

					&:hover {
						.check-img-extra-info {
							opacity: 1;
						}
					}
				}


				.check-img-extra-info {
					position: absolute;
					height: 100%;
					width: 100%;
					left: 0; right: 0;
					top: 0; bottom: 0;
					background-color: rgba($color: #000000, $alpha: 0.33);
					opacity: 0;
					transition: opacity 0.2s ease-in-out;
					// @include default-border;
					display: flex;
					align-items: flex-end;
					text-align: left;
					padding: 0.8em;

					.name {
						color: $white;
					}

					.time {
						color: $white;
						font-size: 0.8em;
					}
				}

				.check-img-info {
					text-align: center;
					font-size: 0.9em;
					padding: 0.2em;
					color: $dark-grey-color;

					&.empty {
						font-size: 0.86em;
						color: lighten($color: $dark-grey-color, $amount: 26%);
					}
				}
			}
		}

		.quality-content-empty {
			color: #a5a5a5;
		}
	}

	.empty-report-data {
		margin: 1em 0;
		padding: 2em;
		background-color: $white;
		text-align: center;
		font-size: 1.4em;
		color: $light-grey-color;
	}

	@media #{$medium-and-down} {
		.quality-container {
			.quality-check-images-wrapper {
				text-align: center;

				.check-img-block {
					margin-right: 0px;
					position: relative;

					&:last-child {
						&::after {
							border-bottom: none;
						}
					}

					&::after {
						position: absolute;
						left: 0; right: 0; bottom: -8px;
						content: "";
						border-bottom: 1px dashed $input-search-icon-color;
					}
				}
			}
		}
	}
}