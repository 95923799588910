/*
=======================
Navigation bar
=======================
*/
$navbar-color: $primary-color;
$navbar-font-color: rgba(255, 255, 255, 0.75); // white

nav {
    position: fixed;
    top: 0px;
    right: 0px;
    left: 0px;
    color: $navbar-font-color;
    background-color: $dark-blue-color;
    height: $navbar-height;
    line-height: $navbar-height;
    padding-bottom: 0px;
    padding-top: 0px;
    margin: 0;
    z-index: 4;
    box-shadow: 0px 3px 6px 0px #00000029;

    .nav-container {
        width: 100%;
        padding-left: 2%;
        padding-right: 10%;

        &::after {
            clear: both;
            content: "";
            display: table;
        }
    }

    a {
        text-decoration: none;
        color: $navbar-font-color;
        background-color: transparent;
        height: 100%;
        width: 100%;
        display: block;
        text-align: center;
    }

    .nav-brand {
        margin-left: 2%;
        margin-right: 4%; // 1% reduce
        height: $navbar-height;
        float: left;
        position: relative;
        padding-top: 6px;

        span {
            color: $white;
            padding-left: $navbar-height - 20;
            font-size: 1.5em;
            @include font-semibold
        }

        .logo-box {
            position: absolute;
            top: $navbar-height / 3;
            left: 0;
            width: $navbar-height;
            height: $navbar-height;
            box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.2);
            background-color: $white;
        }
    }

    .nav-tab {
        width: 9.5em;
        padding: 0 1.5em;
        display: block;
        float: right;
        height: $navbar-height;
        letter-spacing: 0px;
        font-size: 0.8em;
        padding-top: 4px;
        transition: all 0.2s linear;

        &:hover,
        &.active {
            color: $white;
            border-bottom: 2px solid $white;
        }
    }

    .nav-user {
        padding-left: 2em;
        display: block;
        float: right;
        height: $navbar-height;
        position: relative;
        cursor: pointer;

        &.active {
            color: $white;
        }

        .nav-user-content {
            height: $navbar-height;
            display: table;
            font-size: 0.96em;

            .nav-user-info {
                display: table-cell;
                vertical-align: middle;
                line-height: 1;
            }

            .circle {
                background-color: $primary-color;
                width: $navbar-height / 1.6;
                height: $navbar-height / 1.6;
                line-height: $navbar-height / 1.6;
                text-align: center;
                margin-right: 12px;
                margin-bottom: 2px;
                font-size: 1.1em;
            }

            .username {
                color: #fcfcfc;
                font-size: 0.9em;
                text-align: center;
                padding: 0 4px;
                text-overflow: ellipsis;
                max-width: 100px;
                white-space: nowrap;
                overflow: hidden;
            }

            .user-role {
                font-size: 0.7em;
                color: rgba($color: #fcfcfc, $alpha: 0.75);
                text-align: center;
                padding: 3px 4px;
                margin-bottom: -2px;
            }

            .down-arw {
                width: 0;
                height: 0;
                border-left: 5px solid transparent;
                border-right: 5px solid transparent;
                border-top: 4px solid $white;
                margin-top: -4px;
                margin-left: 8px;
            }
        }

        .nav-dropdown {
            top: $navbar-height + 8;
            right: 0;
            font-size: 80%;
            min-width: 12em;
            border-radius: 5px;
            position: absolute;
            background: white;
            z-index: 20;
            box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.2);
            transition: transform 0.2s ease-in-out;
            transform: scale(1, 0);
            transform-origin: top;

            &:before {
                // show the arrow
                position: absolute;
                top: -5px;
                right: 8px;
                content: "";
                height: $navbar-height / 3;
                width: $navbar-height / 4;
                background: $white;
                transform: rotate(45deg);
                z-index: -1;
            }

            .submenu {
                position: relative;
                padding: 10px;
                z-index: 3;
                line-height: $navbar-height / 2;
                font-size: 1.3em;

                &.border-bot {
                    border-bottom: 1px dashed $light-grey-color;
                }
            }

            .submenu-wrap {
                color: $prime-font-color;
                transition: background 0.2s linear;
                padding: 0px 4px;
                text-align: center;

                &:hover {
                    background: darken($white, 5%);

                    &:first-child {
                        border-top-left-radius: 5px;
                        border-top-right-radius: 5px;
                    }

                    &:last-child {
                        border-bottom-left-radius: 5px;
                        border-bottom-right-radius: 5px;
                    }
                }
            }
        }

        &:hover .nav-dropdown {
            transform: scale(1, 1);
        }
    }

    .nav-sidebar-container {
        display: flex;

        .burger-menu-wrapper {
            height: $navbar-height;
            width: $navbar-height;
            display: flex;
            justify-content: center;
            align-items: center;

            .burger-menu {
                @include user-select(none);
                display: flex;
                flex-direction: column;
                justify-content: space-around;
                width: 2rem;
                height: 2rem;
                cursor: pointer;
                padding: 0;
                z-index: 2;

                &:focus {
                    outline: none;
                }

                &.active {
                    div {
                        background-color: #0d0c1d;

                        &:first-child {
                            transform: rotate(45deg);
                        }

                        &:nth-child(2) {
                            opacity: 0;
                            transform: translateX(20px);
                        }

                        &:nth-child(3) {
                            transform: rotate(-45deg);
                        }
                    }
                }

                div {
                    width: 2rem;
                    height: 3px;
                    background-color: $off-white;
                    border-radius: 10px;
                    transition: all 0.2s linear;
                    transform-origin: 1px;
                    @include user-select(none);

                    &:first-child {
                        transform: rotate(0);
                    }

                    &:nth-child(2) {
                        opacity: 1;
                        transform: translateX(0);
                    }

                    &:nth-child(3) {
                        transform: rotate(0);
                    }
                }
            }
        }

        .nav-brand--mobile {
            flex: 1;
            text-align: center;

            span {
                color: $white;
                font-size: 1.1em;
            }
        }

        & + .overlay {
            cursor: default;
            position: fixed;
            top: 0px;
            bottom: 0px;
            right: 0px;
            left: 0px;
            z-index: 0;
            background-color: rgba(0, 0, 0, 0.43);
        }
    }

    .nav-sidebar-wrapper {
        position: fixed;
        top: 0;
        bottom: 0;
        height: 100vh;
        width: 240px;
        background-color: $off-white;
        transition: left 0.2s linear;
        box-shadow: 0px 3px 9px 0px #00000029;
        padding-top: $navbar-height;

        &.slide-out {
            left: -240px;
        }

        &.slide-in {
            left: 0px;
        }

        .nav-sidebar-content-wrapper {
            height: 100%;
            overflow-y: auto;
            padding: 10px 0;
        }

        .nav-sidebar {
            overflow: auto;

            .nav-sidebar-tab {
                color: $dark-blue-color;
                position: relative;
                border-left: 4px solid transparent;
                cursor: pointer;
                text-align: center;
                transition: all 0.2s linear;
                @include user-select(none);

                &.active,
                &:hover,
                &:focus {
                    background-color: rgba($color: $primary-color, $alpha: 0.111);
                    border-left: 4px solid $primary-color;
                }
            }

            .nav-expand-menu {
                .nav-expand-menu-tab {
                    display: flex;
                    justify-content: space-between;

                    .nav-label {
                        flex: 1;
                        padding-left: $navbar-height;
                    }

                    .nav-icon {
                        width: $navbar-height;
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        .right-arrow-icon {
                            transition: transform 0.2s ease-in-out;

                            .right-arrow-path {
                                fill: $primary-color;
                            }
                        }
                    }

                    &.active {
                        .nav-icon {
                            .right-arrow-icon {
                                transform: rotate(90deg);
                            }
                        }
                    }
                }
                .nav-expand-submenu {
                    background-color: darken($color: $off-white, $amount: 2%);
                    max-height: 0px;
                    overflow: hidden;
                    transition: max-height 0.2s ease-in-out;

                    &.show {
                        max-height: fit-content;
                        overflow: visible;
                    }
                }
            }

            .logout-tab {
                cursor: pointer;
                & > div {
                    margin: 0 auto;
                    text-align: center;
                }
            }
        }

        .avatar-wrapper {
            padding-bottom: 1.5em;
            text-align: center;

            .profile-img-circle {
                border-radius: 50%;
                box-shadow: 0px 3px 6px 0px #00000029;
                width: 150px;
                height: 150px;
                background-color: $light-grey-color;
                overflow: hidden;
                position: relative;
                margin: 0 auto;
            }

            .username {
                color: $primary-font-color;
                font-size: 1.5em;
                @include font-medium;
                padding-top: 1em;
                padding-bottom: 0.4em;
                line-height: 1.1;
            }

            .userrole {
                color: $dark-grey-color;
                font-size: 1.1em;
                line-height: 1.4;

                span {
                    border-top: 1px solid $dark-grey-color;
                    padding: 3px 6px 0px 6px;
                }
            }
        }
    }

    @media #{$medium-only} {
        .nav-container {
            padding-right: 1%;
            padding-left: 1%;
        }

        .nav-brand {
            margin-right: 0%;
            margin-left: 1%;

            span {
                padding-left: 0px;
            }
        }

        .nav-user {
            padding-left: 1em;
        }

        .nav-tab {
            width: 6.6em;
            padding: 0 1em;
        }
    }
}
