.report-page {
    .report-engineer-table {
        width: 100%;
        color: #808185;
        border-spacing: 0;
        border-radius: 6px;
        border: 1px solid silver;
        margin-top: 1em;
        margin-bottom: 2em;

        &.auto-width {
            th, td {
                width: auto !important;
            }
        }

        &:last-child {
            margin-bottom: 0;
        }

        .title-col {
            width: 30%;
        }
        .summery-col {
            width: 40%;
        }
        .status-col {
            width: 90px;
        }

        .title-col-date {
            font-size: 0.84em;
            padding-top: 2px;
        }

        th {
            padding: 1em;
            color: rgba(0,0,0,.57);
            font-size: 1.04em;
            @include font-medium
        }

        td {
            padding: .8em .8em .8em 1em;
            border-right: 1px solid silver;
            border-top: 1px solid silver;

            &:last-child {
                border-right: none;
            }
        }

        .header-row {
            th {
                background-color: #e0e0e0;
                &:first-child {
                    border-top-left-radius: 6px;
                }
                &:last-child {
                    border-top-right-radius: 6px;
                }
            }
        }

        .s-schedule {
            color: gray;
        }

        .s-waiting {
            color: #FF0000;
        }

        .s-running {
            color: #efaa33;
        }

        .s-complete {
            color: #10C41C;
        }
    }
}