#project-details {
    .image-gallery-slides {
        height: calc(100vh - 86px);

        .image-gallery-slide {
            height: 100%;

            .image-gallery-block {
                display: flex;
                flex-direction: column;
                height: 100%;

                .image-gallery-image {
                    max-width: 100%;
                    max-height: calc(100vh - 200px);
                    width: auto;
                    height: auto;
                    margin: auto;
                    flex: 1;
                }

                .image-gallery-text {
                    color: white;
                    line-height: initial;
                    padding: 1em;
                    white-space: break-spaces;

                    .caption {
                        font-size: 0.9em;
                        padding-top: 2px;
                    }
                }
            }
        }
    }

    .image-gallery-fullscreen-close {
        position: absolute;
        top: 1em;
        right: 18px;
        cursor: pointer;

        .close-icon {
            width: 40px;
            height: 40px;
            fill: #fff;
            transition: all 0.2s linear;

            &:hover {
                fill: #337ab7;
                transform: scale(1.1);
            }
        }
    }

    .image-gallery-thumbnails-wrapper {
        .image-gallery-thumbnail {
            max-height: 92px;
            margin-top: -4px;
            overflow: hidden;

            .image-gallery-thumbnail-image {
                max-width: 92px;
                max-height: 92px;
                object-fit: contain;
            }
        }
    }
}
