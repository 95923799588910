.page-not-found {
    display: table;
    width: 100%;
    height: calc(100% - #{$navbar-height});
    background-color: $main-bg-color;

    .page-not-found-col {
        display: table-cell;
        vertical-align: middle;

        .page-not-found-content {
            width: 90%;
            max-width: 500px;
            margin: 0 auto;
            border-radius: 4px;
            text-align: center;

            .big-title {
                font-size: 15em;
                line-height: 1.2;
                color: $primary-color;
                @include font-light
            }

            h2 {
                color: $dark-grey-color;
                padding: 0 12px;
            }

            .btn {
                max-width: 38%;
                margin: 0 1%;
            }

            .unauthorised-icon {
                max-width: 75%;
            }

            @media only screen and (max-width: $small-screen-up) {
                font-size: 10px;

                .btn {
                    font-size: 1.2em;
                }
            }
        }
    }
}