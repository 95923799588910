$input-font: 1em;

// common form classes
.form-group {
    position: relative;
    padding-top: 1.2em;
    padding-bottom: 1em;

    &.error {
        .label {
            color: $error-text-color;
        }

        .input-error {
            color: $error-text-color;
            padding: 2px 4px 0 1.4em;
            font-size: 0.7em;
            text-align: left;
        }
    }

    .label {
        position: absolute;
        display: inline-block;
        font-size: $input-font - 0.1;
        height: $input-font;
        color: $primary-font-color;
        padding: 0 2px;
        top: 0.4em;
        left: 1em;
        z-index: 1;
        white-space: nowrap;
    }

    input {
        height: 2.5em;
        width: 100%;
        outline: none;
        border: none;
        border-radius: 0.3rem;
        font-size: $input-font;
        padding: 0 $input-font;
        background-color: #f7f7f7;
        color: $primary-font-color;
        @include placeholder($input-placeholder-color);
        box-shadow: 0px 0px 1px 0px #00000029;
    }

    .yellow {
        color: #efaa33;
        font-size: .85em;
    }

    .form-info-box {
        height: 2.5em;
        width: 100%;
        display: flex;

        .form-info-box-label {
            line-height: 2.5em;
            margin-right: 1em;
            white-space: nowrap;
        }

        .form-info-box-text {
            flex: 1;
            text-align: center;
            line-height: 2.5em;
            outline: none;
            border: none;
            border-radius: 0.3rem;
            font-size: $input-font;
            padding: 0 $input-font;
            background-color: #f7f7f7;
            color: $primary-font-color;
            box-shadow: 0px 0px 1px 0px #00000029;
            white-space: nowrap;
        }
    }
}

.search-input {
    height: 40px;
    width: 100%;
    outline: none;
    border: none;
    border-radius: 4px;
    font-size: $input-font;
    padding: 0 $input-font;
    background-color: $off-white;
    color: $primary-font-color;
    box-shadow: 0px 2px 4px 0px #00000029;
    @include placeholder(rgba(64, 64, 64, 0.35))
}