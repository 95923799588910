$divider-color : #E0E0E0;
$main-bg-color: rgb(247, 247, 247);

$primary-color: #3B42D9;
$primary-font-color: #404040;

$cool-grey-color: rgb(140, 146, 172);
$dark-blue-color: #2B1C8C;
$dark-grey-color: #707070;
$light-grey-color: #B9B9B9;
$prime-font-color: rgba(0, 0, 0, 0.57);
$input-placeholder-color:  rgba(64, 64, 64, 0.25);
$input-search-icon-color:  #70707082;
$error-text-color: #FF0000;

$off-white: rgb(252, 252, 252);

$white: #fff;
$black: #000;

$success-color: #10C41C;
$error-color: #FF0000;

$navbar-height: 50px;
$banner-height: 210px;
$project-title-height: 40px;

// sidebar constants
$sidebar-width: 104px;
$bottom-navbar-height: 56px;
