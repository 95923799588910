#project-list-page {
  --block-width: 9.37em;
  --block-height: 9.37em;

  .pl-action-container {
    width: 100%;
    margin-bottom: 3em;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .pl-left-wrapper {
      flex: 3;

      .global-search-container {
        max-width: 420px;
        min-width: 330px;
      }
    }

    .pl-right-wrapper {
      flex: 1;
      display: flex;

      .add-btn-wrapper {
        max-width: 160px;
        min-width: 120px;
        margin-left: auto;
      }
    }

    &.pl-mobile-action-container {
      .pl-left-wrapper {
        flex: 1 1 auto;

        .global-search-container {
          max-width: 100%;
          min-width: 1px;
          width: 100%;
          padding-right: 1em;
        }
      }
      .pl-right-wrapper {
        flex: 1 1 auto;

        .add-btn-wrapper {
          max-width: 40px;
          min-width: 40px;
          width: 40px;
        }

        .rounded-btn {
          border-radius: 4px;

          svg {
            display: block;
            margin: 0 auto;
            width: 18px;
            height: 18px;

            line {
              stroke: white;
            }
          }
        }
      }
    }
  }

  .project-list-wrapper {
    width: 100%;

    .project-list-container {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
    }
  }

  // for iPhone 5s
  @media screen and (max-width: 400px) {
    --block-width: 6em;
    --block-height: 9.7em;
  }

  // for normal devices
  @media screen and (min-width: 401px) and (max-width: 465px) {
    --block-width: 8em;
    --block-height: 9.7em;
  }

  .project-form-container {
    padding: 1em;
    border: 1px solid $divider-color;
    border-radius: 6px;

    .unit-wrapper {
      .row {
        margin: 0 20px;
        padding: 10px 0;
        // border-bottom: 1px solid $divider-color;
      }

      .unit-row {
        border: 1px solid $divider-color;
        border-radius: 6px;
        margin-bottom: 10px;
      }

      .subunit-row {
        border-bottom: 1px solid $divider-color;

        &:last-child {
          border-bottom: none;
        }
      }
    }
  }
}

.project-block {
  position: relative;
  border-radius: 4px;
  width: calc(var(--block-width) * 3);
  height: var(--block-height);
  margin-bottom: 1.3em;
  background-color: $white;
  overflow: hidden;
  box-shadow: 0px 1px 8px 2px rgba(0, 0, 0, 0.14);

  // wrapper around 2 blocks(.pb-inner-block) that will slide in and out
  .pb-wrapper {
    position: absolute;
    z-index: 0;
    width: 200%;
    height: inherit;
    left: 0;
    top: 0;
    content: "";
    transition: all 0.2s ease-in-out;

    &.move-card {
      transform: translateX(-50%);
    }
  }

  .pb-inner-block {
    display: inline-block;
    vertical-align: top;
    width: 50%;
    height: inherit;
    position: relative;
    // must be bellow settings btn
    z-index: 0;

    .pb-img {
      float: left;
      width: var(--block-width);
      height: var(--block-height);

      img {
        width: var(--block-width);
        height: var(--block-height);
        object-fit: cover;
      }
    }

    .pb-content {
      float: left;
      // this hack required for firefox
      width: calc((var(--block-width) * 2) - 0.1px);
      height: var(--block-height);
      padding: 0.8em;

      .pb-title {
        font-size: 1.3em;
        padding-right: 2.3em;
        padding-bottom: 0.76em;
        color: $primary-font-color;
      }

      .pb-table {
        font-size: 0.72em;
        color: $dark-grey-color;

        .pb-table-row {
          border-top: 1px dashed silver;
          height: 2.6em;
          line-height: 2.6em;
          white-space: nowrap;
        }
      }
    }
  }

  .project-action-icon {
    cursor: pointer;
    position: absolute;
    width: 3em;
    height: 3em;
    border-radius: 4px;
    right: 0px;
    text-align: center;
    padding-top: 0.8em;
    transition: all 0.2s linear;
    z-index: 2;

    &:hover {
      background-color: #efefef;
    }
  }

  .p-actions {
    display: flex;
    flex-direction: column;
    height: 100%;
    transition: all 0.2s linear;

    & > a {
      color: $primary-color;
      text-align: center;
      line-height: calc(var(--block-height) / 2);
      flex: 1;

      &:hover {
        background-color: #efefef;
      }
    }
  }
}
// media query in ordered, if order change, this will override 5s values
// for almost mobiles
@media screen and (max-width: 495px) {
  // --block-width: 7.2em;
  // --block-height : 9.9em;

  // update project block
  .project-block {
    height: auto;

    .pb-wrapper {
      position: unset;
      display: flex;
    }

    .pb-inner-block {
      .pb-img {
        height: 100%;

        img {
          height: inherit;
        }
      }

      .pb-content {
        height: 100%;
      }
    }
  }
}
