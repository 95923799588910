#dashboard {
    .page-content-wrapper {
        max-width: 1000px;

        .project-summery-wrapper {
            overflow-y: auto;
            padding: 0 8px;
        }

        .heading {
            color: $primary-font-color;
        }
    }

    .project-summery-wrapper {
        --block-width : 10vw;
        --block-height : 8vw;

        .project-block-wrapper {
            background: white;
            padding: 1em 2em;
            margin-bottom: 2em;
            box-shadow: 0px 1px 8px 2px rgba(0, 0, 0, 0.14);

            .project-block {
                box-shadow: none;
                margin: 1em auto
            }

            .db-planning-list-wrapper {
                .db-pl-block {
                    padding: 1em;
                    display: flex;
                    flex-direction: column;

                    .title {
                        color: $primary-font-color;
                        font-size: 1.1em;
                        order: 1;
                    }

                    .date {
                        padding-top: 4px;
                        font-size: 0.82em;
                        color: $dark-grey-color;
                        text-align: right;
                        order: 2;
                    }

                    .summery {
                        color: darken($dark-grey-color, 10%);
                        padding-top: 1em;
                        font-size: 1em;
                        text-align: center;
                        order: 3;
                    }

                    .info {
                        color: $dark-grey-color;
                    }
                }

                .db-pl-block:nth-of-type(even) {
                    border-radius: .5em;
                    background-color:#f0efef;
                }
            }

            .project-block-banner {
                padding-top: 10px;
                padding-bottom: 10px;
                border-radius: 4px;
                
                .project-banner-content {
                    margin-left: auto;
                    margin-right: auto;
                    box-shadow: none;
                    padding: 0px;

                    .project-ban-content-col {
                        .label {
                            min-height: 3.2em;
                            display: flex;
                            align-items: flex-end;
                        }
                    }
                }

                .heading {
                    color: #6054a8;
                    margin-bottom: 10px;
                    margin-top: 0px;
                    font-size: 1.5em;
                    font-weight: bold;
                    padding: 8px 1em;
                }

                .btn {
                    margin-top: 1em;
                    max-width: 15em;
                    color: #fff;
                }

                .project-no-pln-text {
                    font-size: 1.4em;
                    padding-left: 1em;
                    padding-right: 1em;
                }

                .project-no-pln {
                    display: flex;
                    align-items: center;
                    max-width: 800px;
                    margin: 0 auto;

                    .project-no-pln-col {
                        flex: 1;

                        img {
                            width: 80%;
                        }
                    }
                }
            }
        }

        .project-summery-title {
            text-align: center;
            color: $primary-font-color;
			padding-bottom: 8px;
			border-bottom: 1px solid $input-search-icon-color;
        }
    }

    @media #{$small-and-down} {
        .page-content-wrapper {
            flex-direction: column;
            height: auto !important;
            width: 92%;

            .project-summery-wrapper {
                flex: auto;

                .project-banner-content {
                    margin-bottom: 0px;
                    font-size: 12px;
                    flex-direction: column;
                    margin-top: 0.4em;

                    .project-ban-content-info-col {
                        margin-bottom: 1em;
                    }

                    .project-ban-content-col {
                        .label {
                            font-size: 0.6em;
                        }
                        .value {
                            font-size: 1.9em;
                        }
                    }
                }

                .project-no-pln {
                    flex-direction: column;
                    padding-top: 1em;

                    .project-no-pln-text {
                        text-align: center;
                        padding: 0;
                        padding-bottom: 1em;
                        font-size: 1.2em;
                    }
                }
            }
        }

        .project-summery-wrapper {
            .project-block-wrapper {
                .db-pl-block {
                    .date {
                        order: 3 !important;
                    }
                    .summery {
                        order: 2 !important;
                    }
                }
            }
        }
    }
    
	@media only screen and (min-width: $small-screen) and (max-width: $medium-screen) {
        .page-content-wrapper {
            width: 92%;

            .project-banner-content {
                font-size: 8px;
                margin: 10px auto 0px auto;
            }
        }
    }

}