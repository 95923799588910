$dropdown-border: $divider-color;
$dropdown-backgroud: #fff;

.chain-selection-dropdown {
    position: relative;

    .icon-wrapper {
        position: absolute;
        top: 0.4em;
        left: 28em;
        display: inline-block;
        cursor: pointer;

        .check-icon {
            height: 22px;
            width: 22px;
            fill: #707070
        }
    }
    // dropdown container
    .dropdown-wrapper {
        // positioning component
        position: absolute;
        // width: 100%;
        top: 3.5em;
        left: 0;
        z-index: 2;
        width: 580px;

        .dropdown-container {
            // main container with the border management
            border: 1px solid $dropdown-border;
            border-radius: 6px;
            background: $dropdown-backgroud;
            width: 100%;

            .listview-header {
                padding: 0.25em;
                border-bottom: 1px solid $dropdown-border;

                .textbox-wrapper {
                    position: relative;
                    // react will calculate width according to col. no.
                    // width: 25%;
                    display: inline-block;
                    padding: 0em 0.25em;

                    &.header {
                        // class with pure text heading
                        color: $dark-grey-color;
                        font-size: 0.85em;
                        padding-left: 1.5em;
                    }

                    &:first-child {
                        padding-left: 0px;
                    }
                }
            }
        }

        &.right:before,
        &.left:before {
            top: -23px;
            position: absolute;
            border-color: transparent transparent $dropdown-backgroud transparent;
            border-style: solid;
            border-width: 12px;
            height: 0px;
            width: 0px;
            content: "";
            bottom: -1px;
            z-index: 11;
        }

        &.right:before {
            right: 80px;
        }

        &.left:before {
            left: 80px;
        }

        &.right:after,
        &.left:after {
            top: -24px;
            position: absolute;
            border-color: transparent transparent $dropdown-border transparent;
            border-style: solid;
            border-width: 12px;
            height: 0px;
            width: 0px;
            bottom: 0;
            content: "";
        }

        &.right:after {
            right: 80px;
        }

        &.left:after {
            left: 80px;
        }

        .listview-table {
            min-height: 14em;

            .listview-content {
                display: inline-block;
                vertical-align: top;
                height: 14em;
                overflow-y: auto;
                margin: 0;
                padding: 0;
                color: $dark-grey-color;
                border-right: 1px solid $dropdown-border;

                &:last-child {
                    border-right: none;
                }

                .listview-tabs {
                    position: relative;
                    font-size: 0.8em;
                    cursor: pointer;
                    height: 2.5em;
                    line-height: 2.5em;
                    padding: 0em 1.2em;
                    border-top: 1px solid $dropdown-border;
                    @include transition(0.2s color);
                    white-space: nowrap;

                    &:first-child {
                        border-top: none;
                    }

                    .listview-arrow {
                        display: none;
                        position: absolute;
                        padding: 0px 5px;
                        font-size: 0.8em;
                        right: 0px;
                        top: 0px;
                        bottom: 0px;
                        background: #fff;

                        .fa {
                            margin-top: 0.25em;
                        }
                    }

                    &:hover,
                    &.active {
                        color: $primary-color;

                        .listview-arrow {
                            display: block;
                        }
                    }
                }
            }
        }
    }
}
