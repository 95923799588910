
.page-inline-tab {
    display: inline-block;

    .tab {
        display: inline-block;
        vertical-align: middle;
        text-align: center;
        color: rgba($color: $dark-blue-color, $alpha: 0.75);
        font-size: 0.9em;
        padding: 0.4em 1em;
        border-bottom: .28rem solid $divider-color;
        transition: all .2s linear;
        cursor: pointer;
        position: relative;
        transition: all 0.2s linear;
        min-width: 7em;

        &.active {
            @include font-bold;
            border-bottom-color: $primary-color;
            color : $dark-blue-color;

            &::after {
                opacity: 1;
            }
        }

        &::after {
            content: "";
            position: absolute;
            bottom: -1px;
            left: 0;
            right: 0;
            margin-left: auto; 
            margin-right: auto; 
            width: 0;
            height: 0; 
            border-left: 3px solid transparent;
            border-right: 3px solid transparent;
            border-bottom: 3px solid $primary-color;
            opacity: 0;
        }
    }
}