.switch {
    position: relative;
    display: inline-block;
    width: 52px;
    height: 14px;

    & input {
        display: none;
    }

    input:checked + .slider {
        background-color: #2ab934;
    }
    
    input:focus + .slider {
        box-shadow: 0 0 1px #2196f3;
    }
    
    input:checked + .slider:before {
        -webkit-transform: translateX(38px);
        -ms-transform: translateX(38px);
        transform: translateX(38px);
    }

    input:checked + .slider:after {
        content: "Active";
        left: 38%;
    }

    .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #ca2222;
        -webkit-transition: 0.4s;
        transition: 0.4s;
        border-radius: 14px;

        &::before {
            position: absolute;
            content: "";
            height: 12px;
            width: 12px;
            left: 1px;
            margin-top: 1px;
            background-color: white;
            -webkit-transition: 0.4s;
            transition: 0.4s;
            border-radius: 50%;
        }

        &::after {
            content: "Inctive";
            color: white;
            display: block;
            position: absolute;
            transform: translate(-50%, -50%);
            top: 50%;
            left: 62%;
            font-size: 0.6em;
        }

        &.loading {
            background-color: grey;
        }
    }
}
