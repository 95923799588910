.project-planning-container {

    .project-planninglist {
        overflow: auto;
        padding-top: 1.4em;
        padding-bottom: 1.4em;
    }

    .planning-block {
        background: white;
        padding: 1em;
        margin-bottom: 1em;
        box-shadow: 0px 3px 6px 0px #00000029;

        .pl-block-title {
            padding: 0.3em;
            border-bottom: 1px solid $divider-color;

            small {
                color: grey;
            }
        }

        .pl-block-content {
            padding-top: 0.5em;

            .pl-block-content-status {
                display: inline-block;

                small {
                    color: grey;
                }

                .grey {
                    color: grey;
                }
                .green {
                    color: #10c41c;
                }
                .yellow {
                    color: #efaa33;
                }
            }
        }

        .pl-block-content-date {
            float: right;
            color: grey;
            font-weight: lighter;
            padding-left: 0.3em;

            .date {
                font-weight: bold;
            }
        }

        .pl-block-content-bottom {
            color: grey;

            & > div {
                display: inline-block;
                text-align: center;
                padding: 0.3em;
            }
        }

        .pl-block-btn-wrapper {
            text-align: right;

            & > a {
                width: 15em;
                display: inline-block;
                margin-left: 1em;
            }
        }
    }
}
