$floating-btn-size:38px;
$floating-btn-plus-icon: 22px;


.btn {
	cursor: pointer;
	display: inline-block;
	vertical-align: middle;
	text-align: center;
	transition: all 0.2s;
	width: 100%;
	height: 40px;
	line-height: 40px;
	border-radius: 4px;
	box-shadow: 0px 3px 6px 0px #00000029;;
	font-size: 0.96em;
	border: none;
	padding: 0;
	margin: 0;
	
	&:focus {
		outline: none;
	}

	&:active {
		border-style: none;
	}
}

.rounded-btn {
	display: inline-block;
	vertical-align: middle;
	cursor: pointer;
	text-align: center;
	transition: all 0.2s;
	width: 40px;
	height: 40px;
	line-height: 40px;
	border-radius: 50%;
	box-shadow: 0px 3px 6px 0px #00000029;;
	font-size: 0.96em;
	border: none;
}

.primary-btn {
	background: $primary-color;
	color: $white;

	&:hover {
		background: darken($primary-color, 4%);
	}
}

.secondary-btn {
	background: $white;
	color: $primary-color !important;

	&:hover {
		background: darken($white, 4%);
	}
}

.disable-btn, .loading-btn {
	color: $white;
	background: $dark-grey-color;
	pointer-events: none; // to prevent hover effect
}


// seperate sub button menu 
.option-content-wrapper {
	padding-top: 0.5em;
	padding-bottom: 0.5em;
	text-align: left;

	.option-btn{
		display: inline-block;
		margin: .2em;
		padding: 0 2em;
		font-size: 0.88em;
		height: 36px;
		line-height: 36px;
		border-radius: 8px;
		background-color: #f1f1f1;
		color: #6c6b6b;
		cursor: pointer;
		transition: .2s all ease-in;

		&.active{
			background-color: $primary-color;
			color: $white;
		}

		&.disabled {
			background-color: grey;
			color :  $white;
			cursor: default;
		}
	}
}


.floating-btn{
	color: white;
	position:absolute;
	cursor:pointer;
	width: $floating-btn-size;
	height: $floating-btn-size;
	text-align: center;
	border-radius: 50%;
	box-shadow: 0 15px 25px 2px #c0c0c0;
	transition: .2s all ease-in;
	right:35px;
	bottom: 25px;
	background: $primary-color;
	display: flex;
	justify-content: center;
	align-items: center;

	@media #{$small-and-down} {
		bottom: $bottom-navbar-height + 10px;
		right: 20px;
	}

	&:hover {
		box-shadow: 0 15px 25px 2px #aaa;
	}

	&.loading {
		// override other background colors
		background: grey !important;
	}

	.plus-icon {
		width: $floating-btn-plus-icon;
		display: block;
		margin: 0 auto;

		line {
			stroke: white;
			stroke-width: 3px;
		}
	}
}