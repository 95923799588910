.loader-wrapper {
	margin: 50px auto;

	svg {
		display: block;
	}

	& > div {
		margin-top: .8em;
		font-size: 2em;
		font-weight: bolder;
		text-align: center;
	}
}