/**
Component:
	ReportPage
	ProjectReport
	StatusCheckBox

**/

$checkbox-size: 2.15em;

.report-page {
	/** 	ReportPage		**/
	.report-form-wrapper {
		margin-top: 1em;
		padding: 1em 3em;
		background-color: $white;

		.report-form-select {
			margin: 0 auto;
			max-width: 30em;

			.form-group {
				padding-top: 0;
			}
		}
	}
	/**		ProjectReport		**/
	.report-content-wrapper {
		padding-bottom: 20px;
		
		.report-content-info-content {
			margin: 2em 0;
			display: flex;
			align-items: center;
			justify-content: space-between;

			.report-content-info-title {
				color: $primary-font-color;
				font-size: 1.6em;
				padding: 0 1em;
				@include font-bold;
			}

			.report-content-info-action {
				min-width: 14em;
				margin: 0 1.4em;

				&.multi-actions {
					display: flex;
					min-width: 20em;

					.btn {
						width: 50%;
						margin-left: 1.4em;
					}
				}

			}
		}

		.report-content-empty {
			margin-top: 1em;
			margin-bottom: 1em;

			.label {
				color: #a5a5a5;
			}
		}

		.no-bot-margin {
			margin-bottom: 0px;
		}
	}
	// content outof wrapper to use general in planning page
	.report-content-block {
		background: white;
		margin: 1em 0;
		padding: 2em;

		.report-content-block-title {
			@include font-medium;
			font-size: 1.2em;
			color: $primary-font-color;
		}

		.divider {
			border-bottom: 1px solid $input-search-icon-color;
			margin: 0.6em 0;
		}

		.report-content {
			width: 100%;
			border-spacing: 0px;

			td {
				vertical-align: middle;
				padding: 8px;
			}

			.report-content-title {
				width: 35%;
				color: $dark-grey-color;
				font-size: 1.1em;
				word-break: break-all;
			}

			.report-content-dates {
				width: 30%;
				color: $dark-grey-color;
				background-color: #f5f5f5;
				text-align: center;
				word-break: break-all;
			}

			.report-content-info {
				width: 30%;
				color: $dark-grey-color;
				text-align: center;
				word-break: break-all;
			}

			.report-content-status {
				width: 10%;
				padding: 6px 0;

				.report-status-container {
					.report-checkbox-wrapper {
						margin: 0 auto;
					}
				}
			}
		}
	}
	/**		StatusCheckBox		**/
	.report-checkbox-wrapper {
		width: $checkbox-size;
		height: $checkbox-size;

		&.green {
			.checkbox-circle {
				background-color: #10C41C;
			}
		}

		&.yellow {
			.checkbox-circle {
				background-color: #efaa33;
			}
		}

		.checkbox-circle {
			width: $checkbox-size;
			height: $checkbox-size;
			border-radius: 50%;
			box-shadow: 0px 0px 0px 1px $white;
			background-color: transparent;
			position: relative;

			&::after {
				content: "";
				position: absolute;
				left: $checkbox-size / 3;
				top: $checkbox-size / 7.9;
				width: $checkbox-size / 3.79;
				height: $checkbox-size / 1.67;
				border: solid $white;
				border-width: 0px 2px 2px 0;
				transform: rotate(45deg);
			}
		}
	}

	@media #{$small-and-down} {
		width: 92%;

		.report-content-wrapper {
			.report-content-info-content {
				flex-direction: column;

				.report-content-info-action {
					margin: 1.4em;
					margin-bottom: 0;
				}
			}
		}
		.report-content-block {
			font-size: 12px;
		}
	}

	@media #{$medium-and-down} {
		width: 92%;

		.report-form-wrapper {
			padding: 1em;
		}
	}
}
