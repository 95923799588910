#profile-page {


    .profile-content {
        border-radius : 4px;
        box-shadow: 0px 3px 6px 0px #00000029;
        background-color: $off-white;
        margin: 4% auto;
        padding: 8%;

        .password-group {
            .show-password {
                margin-top: -0.5em;
                
                .checkmark {
                    &::after {
                        top: 4px;
                    }
                }
                .checkbox-label {
                    font-size: 0.9em;
                }
            }
        }

        .input-group {

            .single {
                width: 50%;
                display: inline-block;

                &.left-inp {
                    padding-right: 4px;
                }

                &.right-inp {
                    padding-left: 4px;
                }
            }
        }

        .btn-wrapper {
            text-align: center;
            margin-top: 2em;
            margin-bottom: 2em;
            
            .btn {
                max-width: 240px;
            }
        }

        .profile-info-wrapper {
            margin-bottom: 3em;
            
            &::after {
                clear: both;
                display: table;
                content: "";
            }

            .profile-img-col {
                width: 40%;
                float: left;

                .profile-img-circle {
                    border-radius: 50%;
                    box-shadow: 0px 3px 6px 0px #00000029;
                    width: 150px;
                    height: 150px;
                    background-color: $light-grey-color;
                    overflow: hidden;
                    position: relative;
                    cursor: pointer;

                    img {
                        border-radius: 50%;
                        width: 150px;
                        height: 150px;
                        object-fit: cover;
                    }

                    input {
                        width: 100%;
                        height: 100%;
                        position: absolute;
                        z-index: 1;
                        opacity: 0;
                        cursor: pointer;
        
                        &:hover {
                            & + .upload-wrapper {
                                background-color: rgba($color: #000000, $alpha: 0.7);
                            }
                        }
                    }

                    .upload-wrapper {
                        position: absolute;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        width: 100%;
                        text-align: center;
                        z-index: 0;
                        cursor: pointer;
                        background-color: rgba($color: #000000, $alpha: 0.5);
                        padding-bottom: 0.6em;
                        padding-top: 0.4em;
                        transition: background-color 0.2s linear;
        
                        .upload-text {
                            font-size: 0.7em;
                            color: $white;
                        }
                    }
                }
            }

            .profile-info-col {
                width: 60%;
                float: left;

                .name {
                    color: $primary-font-color;
                    font-size: 1.5em;
                    @include font-medium;
                    padding-top: 1.2em;
                }

                .role {
                    color: $dark-grey-color;
                    font-size: 1.1em;

                    // span {
                    //     color: $primary-color;
                    //     text-decoration: underline;
                    // }
                }
            }
        }

        .divider {
            height: 3px;
            background-image: linear-gradient(to right, rgb(185, 185, 185) 55%, rgba(255,255,255,0) 0%);
            background-position: bottom;
            background-size: 24px 1px;
            background-repeat: repeat-x;
        }

        #change-password {
            .title {
                margin-top: 2em;
                margin-bottom: 2em;
                text-align: center;
                font-size: 1.2em;
                @include font-medium;
            }
        }
    }

    .invite-content {
        text-align: center;
        padding-top: 30%;

        .big-title {
            font-size: 6em;
            line-height: 1.2;
            color: $primary-color;
            @include font-light;

            @media #{$medium-and-down} {
                font-size: 4em;
            }
        }

        h2 {
            color: $dark-grey-color;
            padding: 0 12px;
        }

        .btn {
            max-width: 38%;
            margin: 0 1%;
        }
    }

    @media screen and (max-width : #{$small-screen-up}) {
        .container {
            width: 92%;

            .profile-content {
                .profile-info-wrapper {
        
                    .profile-img-col {
                        width: 100%;
        
                        .profile-img-circle {
                            margin: 0 auto;
                        }
                    }
        
                    .profile-info-col {
                        width: 100%;
                        text-align: center;
                    }
                }
            }
        }
    }
}