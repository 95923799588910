/**
Use By : 

InvitationListPage
UserList
UserResetPass
**/

.user-form-wrapper {
    width: 40rem;
    padding: 2rem 8rem;

    .info-text {
        padding: 0.2em 1em;
        color: $primary-font-color;

        span {
            @include font-bold;
        }
    }

    .multi-action {
        display: flex;
        padding-bottom: 8px;

        .btn {
            &:first-child {
                margin-right: 0.5em;
            }

            &:last-child {
                margin-left: 0.5em;
            }
        }
    }

    .user-form-del {
        text-align: center;

        .user-form-del-text {
            display: inline-block;
            text-decoration: underline;
            color: $primary-font-color;
            font-size: 0.96em;
            padding-top: 1.2em;
            padding-bottom: 1.6em;
            cursor: pointer;
        }
    }

    .password-group {
        margin-top: 0.7em;

        .show-password {
            margin-top: -0.5em;
        }
    }

    .divider {
        height: 3px;
        background-image: linear-gradient(to right, rgb(185, 185, 185) 55%, rgba(255, 255, 255, 0) 0%);
        background-position: bottom;
        background-size: 24px 1px;
        background-repeat: repeat-x;
        width: 140%;
        margin-left: -20%;
    }

    .permitted-project-wrapper {
        padding: 2.4em 0;
        width: 130%;
        margin-left: -15%;

        .title {
            padding-bottom: 1em;
        }

        .empty-text {
            color: #a5a5a5;
            font-size: 1.1em;
        }

        .perm-table {
            display: table;
            width: 100%;

            .perm-row {
                display: table-row;

                &:nth-child(even) {
                    background-color: #f5f4f4;
                }

                .perm-col {
                    display: table-cell;
                    vertical-align: middle;
                    height: 4.2em;
                }
            }
        }

        .small-col {
            width: 4.4em;
        }

        .pro-img {
            width: 3em;
            height: 3em;
            margin-left: 0.6em;
            background-color: white;
            border-radius: 4px;
            overflow: hidden;
            @include default-shadow;

            img {
                height: 100%;
                width: 100%;
                object-fit: contain;
            }
        }

        .perm-delete {
            width: 2.4em;
            height: 2.4em;
            margin-right: 0.6em;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-left: auto;
            cursor: pointer;
            transition: all 0.2s linear;

            &:hover,
            &.active {
                background-color: darken(#efefef, 4%);
                border-radius: 4px;
            }

            .delete-icon {
                fill: $error-color;
            }
        }

        .info-text {
            color: $dark-grey-color;
            font-size: 1.1em;
        }
    }

    @media screen and (max-width : #{$small-screen-up}) {
        width: 22rem;
        margin: 0 auto;
        padding: 2rem;

        .divider {
            width: 100%;
            margin-left: 0;
        }

        .permitted-project-wrapper {
            width: 100%;
            margin-left: 0;
        }
    }
}
