#project-details {
    .quality-img-add {
        width: 30em;
        padding: 2rem 4rem;

        .title {
            padding-bottom: 1em;
        }

        .quality-img-upload-wrapper {
            display: table;
            padding: 1em 0;

            .quality-img-upload-col {
                display: table-cell;
                vertical-align: middle;
                width: 8em;

                .quality-img-upload-block {
                    width: 8em;
                    height: 8em;
                    background-color: #f5f5f5;
                    border: 1px dashed #bcbbbb;
                    border-radius: 3px;
                    position: relative;
                    overflow: hidden;

                    .quality-img-upload-content {
                        width: 100%;
                        height: 100%;
                        position: absolute;
                        z-index: 0;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }

                    input {
                        width: 100%;
                        height: 100%;
                        position: absolute;
                        left: 0;
                        top: 0;
                        z-index: 1;
                        opacity: 0;
                        cursor: pointer;
                    }

                    img {
                        width: 8em;
                        height: 8em;
                        object-fit: cover;
                        position: relative;
                    }
                }
            }

            .quality-img-info-col {
                display: table-cell;
                vertical-align: middle;
                text-align: left;
                padding-left: 0.86em;

                .heading {
                    color: $dark-grey-color;
                    text-decoration: underline;
                }

                .desc {
                    color: #989797;
                    font-size: 0.76em;
                }
            }
        }

        .quality-action-wrapper {
            .btn {
                margin-top: 2em;
                width: calc(50% - 8px);

                &.primary-btn {
                    background-color: #6054a8;
                }

                &:first-child {
                    margin-right: 8px;
                }

                &:last-child {
                    margin-left: 8px;
                }
            }
        }

        .quality-img-indicator {
            text-align: left;
            margin: 0.4em 0;
            opacity: 0;
            transition: opacity 0.2s linear;

            &.show {
                opacity: 1;
            }

            .img-name {
                font-size: 0.84em;
                line-height: 1.1;
                color: #333131;
            }

            .indicator-wrap {
                & > div {
                    display: inline-block;
                    vertical-align: middle;
                }

                .ind {
                    height: 0.8em;
                    width: calc(100% - 14px);
                    background-color: #00d200;
                    border-radius: 3px;
                    opacity: 0.5;
                }
                .remove {
                    width: 10px;
                    margin-left: 4px;
                    cursor: pointer;
                }
            }
        }

        .react-select-container .react-select__control {
            min-height: 3.2em;
        }

        .quality-select-content {
            margin-top: 6px;
            text-align: left;

            .qs-heading {
                font-size: 0.86em;
                color: $primary-font-color;
                line-height: 1.1;
            }

            .qs-text {
                font-size: 0.7em;
                color: darken($dark-grey-color, 15%);
            }
        }

        .react-select__option--is-selected {
            .quality-select-content {
                .qs-heading {
                    color: darken($white, 2%);
                }

                .qs-text {
                    color: darken($white, 8%);
                }
            }
        }
    }
}
