#project-details {
    // popup
    .pd-check-popup {
        width: 40rem;
        padding: 2rem;

        @media screen and (max-width : #{$small-screen-up}) {
            width: 22rem;
            margin: 0 auto;
        }

        .title {
            padding-bottom: 1em;
        }

        .check-name-content {
            .check-name {
                color: $dark-grey-color;
            }

            .check-subname {
                color: $light-grey-color;
                font-size: 0.92em;

                span {
                    font-size: 0.8em;
                }
            }
        }

        .check-action {
            .btn {
                max-width: 12em;
            }
        }

        .check-info {
            font-size: 1.2em;
            padding-top: 1em;
            text-align: center;
            color: $dark-grey-color;

            span {
                color: $primary-font-color;
                @include font-medium;
            }
        }

        .check-images {
            text-align: left;

            .check-img-title {
                font-size: 1.2em;
                padding: 0.8em 0;
                color: $dark-grey-color;
            }

            .check-images-wrapper {
                display: flex;
                flex-wrap: wrap;

                .check-img-block {
                    width: 17em;
                    margin: 0.5em;
                    border-radius: 4px;
                    padding: 8px;

                    .check-img {
                        width: calc(17em - 16px);
                        height: calc(17em - 16px);
                        object-fit: contain;
                        cursor: pointer;
                    }

                    .check-img-info {
                        padding: 4px;

                        &.blockquote {
                            border-left: 2px solid $cool-grey-color;
                            padding-left: 0.5em;
                            font-size: 1.2em;
                        }

                        .label {
                            color: $dark-grey-color;
                            font-size: 0.8em;
                        }

                        .value {
                            color: $primary-font-color;
                            font-size: 0.7em;
                        }

                        .author {
                            text-align: right;
                            padding-right: 0.5em;
                        }
                    }
                }
            }
        }

        .checkitems-wrapper {
            position: relative;

            .checkitems-download {
                position: absolute;
                right: 8px;
                top: -8px;
                width: 125px;

                .download-btn {
                    outline: none !important;
                    margin-top: 0px;

                    .filedownload-icon {
                        display: block;
                        margin: 0 auto;
                        fill: white;
                        width: 22px;
                    }
                }
            }
            .checkitem-title {
                font-size: 1.4em;
                color: $primary-font-color;
                padding-bottom: 8px;
                border-bottom: 1px solid $input-search-icon-color;
            }

            .checkitems-table {
                display: table;
                width: 100%;

                .checkitems-row {
                    display: table-row;
                    color: $dark-grey-color;

                    &:nth-child(even) {
                        background-color: #f5f5f5;
                    }
                }

                .checkitems-col {
                    display: table-cell;
                    vertical-align: middle;
                    height: 2.4em;
                    padding: 0 8px;

                    &.info-col {
                        width: 75%;
                        padding: 8px;
                        text-align: left;
                    }

                    &.action-col {
                        width: 25%;

                        .rounded-btn {
                            margin: 0.5em;
                            border-radius: 6px;

                            .check-icon {
                                display: block;
                                margin: 0 auto;
                                fill: white;
                            }
                        }
                    }

                    .checkitems-info {
                        display: inline-block;
                        text-align: right;
                        padding-top: 0.66em;
                        padding-bottom: 0.66em;

                        .label {
                            font-size: 0.7em;
                        }

                        .checkitems-info-uncheck {
                            padding-top: 2px;
                            cursor: pointer;

                            &:hover {
                                span {
                                    text-decoration: underline;
                                }
                            }

                            &.loading {
                                span {
                                    color: $dark-grey-color;
                                }
                            }

                            span {
                                display: inline-block;
                                vertical-align: middle;
                                font-size: 0.86em;
                                padding: 0 2px;
                                color: $primary-color;
                            }

                            svg {
                                display: inline-block;
                                vertical-align: middle;
                                width: 0.9em;
                                fill: $primary-color;
                            }
                        }
                    }
                }
            }

            .empty-wrapper {
                color: $dark-grey-color;
                font-size: 1.2em;
                text-align: center;
            }
        }
    }
}
