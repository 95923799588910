.unit-list-page {

    .unit-list-wrapper {
        display: flex;
        margin-top: 2em;
        overflow-x: scroll;
        width: 100%;

        .unit-list-container {
            flex: 1;
            padding: 8px;
            padding-bottom: 0;
            transition: height .2s linear;

            .flex-wrapper {
                flex-wrap: wrap;
                justify-content: space-around;
            }
        }
    }

    .unit-block {
        width: 350px;
        border-radius: 4px;
        background-color: $white;
        position: relative;
        z-index: 0;
        overflow: hidden;
        padding: 1em;
        margin-bottom: 30px;
        margin-right: 30px;
        box-shadow: 0px 3px 6px 0px #00000029;
        cursor: pointer;
        overflow: hidden;

        &.active {
            &::after {
                position: absolute;
                content: "";
                width: 100%;
                height: 5px;
                bottom: 0;
                left: 0;
                background-color: $primary-color;
            }
        }

        .title {
            color: $primary-font-color;
            font-size: 0.96em;
            padding-right: 2em;
            @include font-medium;
        }

        .wrapper {
            color: $primary-font-color;
            font-size: 0.86em;
            padding-top: 0.6em;

            span {
                color: $primary-color;
                letter-spacing: 2px;
            }
        }

        .unit-edit {
            position: absolute;
            top: 0;
            right: 0;
            color: #b9b9b9;
            width: 34px;
            height: 34px;
            line-height: 34px;
            text-align: center;
            transition: all 0.2s linear;

            &:hover {
                background-color: #efefef;
                border-radius: 4px;
            }

            .edit-icon {
                height: 14px;
                width: 14px;
            }
        }
    }

    .unit-model {
        position: absolute;
        top: 0px;
        right: 0px;
        width: 400px;
        height: 100%;
        overflow-y: auto;
        background-color: $white;
        box-shadow: 0px 3px 6px 0px #00000029;
        transition: transform 0.2s linear;

        &.model-out {
            transform: translateX(400px);
        }

        &.model-in {
            transform: translateX(0px);
        }

        .unit-model-wrapper {
            padding: 1.8em 1.6em;
            padding-top: 1.2em;

            .close-icon-wrapper {
                cursor: pointer;
                width: 28px;
                height: 28px;
                transition: all 0.2s linear;
                margin-left: auto;
                text-align: center;
                margin-right: -8px;
                margin-top: -2px;

                &:hover {
                    background-color: rgba(239, 239, 239, 0.5);
                    border-radius: 4px;
                }

                .close-icon {
                    margin-top: 2px;
                    fill: $light-grey-color;
                }
            }

            .unit-box {
                box-shadow: 0px 2px 4px #00000029;
                border: 0.5px solid $dark-grey-color;
                background-color: $white;
                padding: 0.9em 1em;
                margin: 1.1em 0;

                .unit-text {
                    color: rgba($color: $primary-font-color, $alpha: 0.85);
                    font-size: 0.9em;
                }
            }

            .form-wrapper {
                width: 100%;

                .form-label {
                    font-size: 1.1em;
                    color: $primary-font-color;

                    &.sub-title {
                        padding-top: 0.8em;
                        padding-bottom: 1em;
                    }
                }

                .add-sub-btn {
                    border: 1px dashed $primary-font-color;
                    background-color: #fcfcfc;
                    height: 3.1em;
                    line-height: 3em;
                    padding: 0 1em;
                    cursor: pointer;
                    margin: 1.2em 0;

                    .add-sub-btn-text {
                        color: rgba($color: $primary-font-color, $alpha: 0.85);
                        font-size: 0.9em;
                        text-align: center;
                    }
                }

                .subunit-block {
                    background-color: #fcfcfc;
                    box-shadow: 0px 2px 4px #00000029;
                    border: 1px solid $primary-font-color;
                    display: flex;
                    align-items: center;
                    padding: 8px 10px;
                    margin-bottom: 4px;

                    .form-input {
                        flex: 1;

                        input {
                            width: 100%;
                            background-color: transparent;
                            border: none;
                            outline: none;
                            color: rgba($color: $primary-font-color, $alpha: 0.85);
                        }

                        .error-block {
                            padding: 0.4em;
                            text-align: left;
                        }
                    }

                    .arrow-block {
                        .arrow-up {
                            width: 0;
                            height: 0;
                            border-left: 7px solid transparent;
                            border-right: 7px solid transparent;
                            border-bottom: 7px solid #c0c0c0;
                            margin-bottom: 14px;
                            cursor: pointer;
                        }

                        .arrow-down {
                            width: 0;
                            height: 0;
                            border-left: 7px solid transparent;
                            border-right: 7px solid transparent;
                            border-top: 7px solid #c0c0c0;
                            margin-top: 14px;
                            cursor: pointer;
                        }
                    }

                    .ind-text {
                        color: rgba($color: $primary-font-color, $alpha: 0.85);
                        font-size: 0.94em;
                        padding: 0 1em;
                    }

                    .remove-text {
                        color: rgba($color: $primary-font-color, $alpha: 0.75);
                        font-size: 0.86em;
                        padding: 0 0.8em;
                        text-decoration: underline;
                        cursor: pointer;
                    }
                }

                .form-action-btn {
                    padding: 30px 0;

                    .action {
                        width: 50%;
                        display: inline-block;

                        &.left-ac {
                            padding-right: 7px;
                        }

                        &.right-ac {
                            padding-left: 7px;
                        }
                    }
                }

                .delete-action {
                    text-align: center;
                    color: $dark-grey-color;
                    text-decoration: underline;
                    cursor: pointer;
                }
            }
        }
    }

    .unit-form-wrapper {
        width: 40rem;
        padding: 2rem 8rem;

        @media screen and (max-width : #{$small-screen-up}) {
            width: 22rem;
            margin: 0 auto;
            padding: 2rem;
        }

        .unit-form-del {
            text-align: center;

            .unit-form-del-text {
                display: inline-block;
                text-decoration: underline;
                color: $primary-font-color;
                font-size: 0.96em;
                padding-top: 1.8rem;
                cursor: pointer;
            }
        }
    }

    .empty-page-wrapper {
        .btn {
            max-width: 32em;
            margin: 1.4em 0;
        }
    }

    @media screen and (max-width : #{$small-screen-up}) {
        .unit-model {
            width: 300px;

            &.model-out {
                transform: translateX(300px);
            }

            .unit-model-wrapper {
                padding: 1.8em 1.86em;
            }
        }
    }
}
