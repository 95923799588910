#admin-home-page {
    .page-content-wrapper {
        position: relative;
        overflow-y: auto;
        overflow-x: hidden; // for hide side container
        padding: 2.3em 2.3em 0 2.3em;

        .page-right-actions {
            margin-top: 0;
        }
    }
}
