#project-form-page {
    &.project-add-page {
        .page-banner-wrapper {
            background-color: $dark-grey-color;
            box-shadow: inset 0px -9em 9em -9em rgba(0, 0, 0, 0.4);
            position: relative;
            line-height: 15em;
        }

        .project-add-banner {
            display: flex;
            align-items: flex-end;
            height: 100%;
        }

        .project-banner-input {
            width: 100%;
            height: 100%;
            position: absolute;
            z-index: 1;
            opacity: 0;
            cursor: pointer;
        }

        .project-banner-upload-wrapper {
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            top: 0;
            width: 100%;
            height: 100%;
            text-align: center;
            z-index: 0;
            cursor: pointer;

            .project-banner-upload-text {
                font-size: 1.7em;
                color: $white;
                @include font-light;
            }
        }

        .project-banner-img {
            height: 100%;
            width: 100%;
            object-fit: cover;
            position: relative;
        }

        .main-page-content {
            .project-img-col {
                height: 20em;

                .project-img-wrapper {
                    height: 100%;
                    width: 100%;
                    background-color: $white;
                    position: relative;

                    .project-img-input {
                        width: 100%;
                        height: 100%;
                        position: absolute;
                        z-index: 1;
                        opacity: 0;
                        cursor: pointer;
                    }

                    .project-img {
                        height: 100%;
                        width: 100%;
                        object-fit: contain;
                        position: relative;
                    }

                    .project-img-upload-wrapper {
                        position: absolute;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        top: 0;
                        width: 100%;
                        height: 100%;
                        z-index: 0;
                        cursor: pointer;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        flex-direction: column;

                        .text {
                            color: $dark-grey-color;
                        }
                    }
                }
            }

            .project-content-wrapper {
                background-color: $white;
                padding: 2em;

                .action-btn {
                    padding-top: 8em;

                    .btn {
                        display: inline-block;
                        width: calc(50% - 8px);

                        &:first-child {
                            margin-right: 8px;
                        }

                        &:last-child {
                            margin-left: 8px;
                        }
                    }
                }
            }
        }
    }

    &.project-edit-page {

        .project-edit-text {
            font-size: 0.8em;
            padding: 6px;
            color: lighten($color: $primary-color, $amount: 8%);

            .edit-pencil-icon {
                fill: lighten($color: $primary-color, $amount: 8%);
                height: 1em;
                width: 1em;
                margin-left: 1px;
                transform: translateY(1px);
            }

            &:hover {
                text-decoration: underline;
            }
        }

        .page-content-wrapper {
            position: relative;
            overflow-y: auto;
            overflow-x: hidden; // for hide side container
            padding: 2.3em 2.3em 0 2.3em;
        }

        .page-right-actions {
            margin-top: 0;

            .page-action-wrapper {
                text-align: right;
            }
        }

        .project-banner-wrapper {
            display: flex;
            height: 100%;
        }
    }
}
