#holiday-list-page {

    .holiday-form-wrapper {
        width: 40rem;
        padding: 2rem 8rem;

        @media screen and (max-width : #{$small-screen-up}) {
            width: 22rem;
            margin: 0 auto;
            padding: 2rem;
        }

        .form-group {
            input {
                text-align: left;
            }
        }

        .input-group {

            .single {
                width: 50%;
                display: inline-block;

                &.left-inp {
                    padding-right: 4px;
                }

                &.right-inp {
                    padding-left: 4px;
                }
            }
        }
    }
}

.holiday-list-wrapper {
    display: flex;
    margin-top: 2em;
    overflow-x: scroll;
    width: 100%;

    .holiday-list {
        flex: 1;
        padding: 6px;
        padding-bottom: 0;
        transition: height .2s linear;
    }

    .flex-wrapper {
        flex-wrap: wrap;
        justify-content: space-around;
    }
}

.holiday-block {
    border-radius: 4px;
    background-color: $white;
    position: relative;
    width: 350px;
    z-index: 0;
    overflow: hidden;
    padding: 1em;
    margin-bottom: 30px;
    margin-right: 30px;
    box-shadow: 0px 3px 6px 0px #00000029;

    .title {
        color: $primary-font-color;
        font-size: 1.02em;
        @include font-medium;
    }

    .dates-wrapper {
        color: #2E2E2E;
        font-size: 0.86em;
        padding-top: 1em;

        span {
            color: $primary-color;
            letter-spacing: 2px;

            &:first-child {
                padding-right: 16px;
            }
        }
    }

    .holiday-edit {
        position: absolute;
        top: 0;
        right: 0;
        color: #B9B9B9;
        width: 34px;
        height: 34px;
        line-height: 34px;
        text-align: center;
        transition: all .2s linear;

        &:hover {
            background-color: #efefef;
            border-radius: 4px;
        }

        .edit-icon {
            height: 14px;
            width: 14px;
        }
    }
}