$success-color: $success-color;
$error-color: $error-color;
$info-color: $primary-color;
$warning-color: #efaa33;

.notification {
	position: fixed;
	top: 15%;
	right: 15px;
	width: 350px;
	z-index: 100;
	margin: 0;
	padding: 0;

	.content {
		position: relative;
		display: table;
		height: 100px;
		margin-bottom: 20px;
		transition: box-shadow 0.2s linear;
		background-color: white;
		border-radius: 8px;
		overflow: hidden;
		box-shadow: 0px 5px 10px 0px #00000029;

		&::after {
			content: "";
			position: absolute;
			bottom: 0;
			width: 100%;
			left: 0;
			height: 6px;
		}

		&.error {
			&::after {
				background-color: $error-color;
			}
			.icon {
				svg {
					fill: $error-color;
				}
			}
			.title {
				color: $error-color;
			}
		}

		&.success {
			&::after {
				background-color: $success-color;
			}
			.icon {
				svg {
					fill: $success-color;
				}
			}
			.title {
				color: $success-color;
			}
		}

		&.info {
			&::after {
				background-color: $info-color;
			}
			.icon {
				svg {
					fill: $info-color;
				}
			}
			.title {
				color: $info-color;
			}
		}

		&.warning {
			&::after {
				background-color: $warning-color;
			}
			.icon {
				svg {
					fill: $warning-color;
				}
			}
			.title {
				color: $warning-color;
			}
		}

		&:hover {
			box-shadow: 0px 5px 10px 5px #00000029;
		}

		.cancel {
			position: absolute;
			top: 0px;
			right: 0px;
			cursor: pointer;
			height: 30px;
			width: 30px;
			padding: 3px;
			transition: all 0.2s linear;

			.close-icon {
				fill: $light-grey-color;
			}

			&:hover {
				background-color: rgba(239, 239, 239, 0.5);
				border-radius: 4px;
			}
		}

		.icon {
			display: table-cell;
			width: 70px;
			vertical-align: middle;
			text-align: center;

			svg {
				width: 44px;
				height: 44px;
			}
		}

		.text {
			display: table-cell;
			width: 280px;
			vertical-align: middle;
			padding: 10px;
			font-size: 92%;
			color: $dark-grey-color;

			.title {
				font-size: 1.4em;
				@include font-medium;
			}
		}
	}
}

@media (max-width: 320px) {
	// break point for iphone 5/4 screen sizes
	.notification {
		width: 300px;

		.content .text {
			width: 230px;
		}
	}
}
