.planning-form {
    .planning_checklist_form {
        width: 100%;

        .pl-form-wrapper {
            margin-right: 4px;
            margin-left: 4px;
            margin-bottom: 6px;
            
            .pl-checklist-row {
                margin: 0 1em 14px 1em;
                border-bottom: 1px dashed $input-search-icon-color;

                .pl-checklist-content {
                    display: flex;
                    align-items: center;

                    .pl-checklist-content-col {
                        flex: 1;

                        .title {
                            color: $primary-font-color;
                            font-weight: bolder;
                            padding: 0 1em;
                        }
                    }
                }

                .pl-checklist-item-info {
                    padding-top: 4px;
                    padding-bottom: 1em;
                    text-align: center;

                    .title {
                        font-size: 0.9em;
                        color: $primary-font-color;
                        padding-bottom: 4px;
                    }

                    .pl-checklist-pills {
                        span {
                            background-color: #f7f7f7;
                            margin: 0.3em;
                            display: inline-block;
                            color: $primary-font-color;
                            padding: 2px 8px;
                            font-size: 0.9em;
                            border-radius: 0.3rem;
                        }
                    }
                }
            }

            .pl-form-info {
                padding: 0.5em;
                padding-top: 1em;
                font-size: 1.5em;
    
                .small {
                    color : grey;
                    font-size: 0.6em;
                }

                .date {
                    color : $dark-grey-color;
                    font-size: 0.7em;
                    line-height: 1.8;
                }
            }
        }
    }
}