#project-details {
	.page-content-wrapper {
		display: flex;

		// filter sidebar scss in _project_filter

		.page-content {
			flex: 8;
			overflow-y: auto;
			padding: 2.3em 2.3em 0 2.3em;

			// increase 2nd tab for suitable design
			.page-inline-tab {
				.tab:nth-child(2) {
					min-width: 9em;
				}
			}

			// used to show action content only at right side
			.page-right-actions-only {
				width: 100%;

				&::after {
					clear: both;
					content: "";
					display: table;
				}
			}

			.page-right-actions {
				margin-top: 0px;
				text-align: right;

				.form-group {
					width: 70%;
					display: inline-block;
					padding-right: 3em;
					text-align: left;
				}
			}

			.empty-page-wrapper {
				margin-bottom: 0px;

				img {
					width: 36em;
				}

				.btn {
					max-width: 14em;
					margin: 1.4em 0;
				}
			}
		}

		.project-details-page {
			padding: 0;
			position: relative;

			.pd-planning-wrapper {
				padding: 1em 2.3em 0 2.3em;

				@media #{$small-and-down} {
					min-width: 100vw;
					padding: 0;
					padding-top: 1em;

					.pd-planning-col {
						flex: 0 0 100vw;
					}
				}
			}
		}
	}

	.sidebar-column-wrapper {
		// 40px for title bar height 
		top: $navbar-height + $project-title-height;
	}
}

// Project details ---- > Planning list page designs at _project_planning_list
