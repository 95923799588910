.checkbox-container {
    display: table;
    position: relative;
    cursor: pointer;
    @include user-select(none);

    input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;

        &:checked ~ .checkmark {

            &::after {
                display: block;
            }

            .check {
                background-color: $primary-color;
                border-color: $primary-color;
            }
        }
    }

    .checkmark {
        display: table-cell;
        vertical-align: middle;
        width: 12px;

        .check {
            width: 12px;
            height: 12px;
            border-radius: 50%;
            border: 1px solid #B9B9B9;
        }

        &::after {
            content: "";
            position: absolute;
            display: none;
            left: 3px;
            top: 5px;
            width: 3px;
            height: 7px;
            border: solid $off-white;
            border-width: 0px 2px 2px 0;
            transform: rotate(45deg);
        }
    }

    .checkbox-label {
        display: table-cell;
        vertical-align: middle;
        color: $primary-font-color;
        font-size: 0.96em;
        padding-left: 1em;
        padding-right: 1em;
        @include user-select(none);
    }
}