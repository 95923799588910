#pro-perm-list-page {

    .pro-perm-list-wrapper {
        display: flex;
        margin-top: 2em;
        overflow-x: scroll;
        width: 100%;

        .pro-perm-list-container {
            flex: 1;
            padding: 8px;
            padding-bottom: 0;
            transition: height .2s linear;

            .flex-wrapper {
                flex-wrap: wrap;
                justify-content: space-around;
            }
        }
    }

    .pro-perm-block {
        border-radius: 4px;
        width: 350px;
        height: 112px;
        background-color: $white;
        position: relative;
        z-index: 0;
        overflow: hidden;
        margin-bottom: 30px;
        margin-right: 30px;
        box-shadow: 0px 1px 8px 2px rgba(0, 0, 0, 0.14);
        cursor: pointer;

        .pro-perm-data {
            height: inherit;

            &::after {
                clear: both;
                content: "";
                display: table;
            }

            .common-image {
                width: 100px;
                height: 100%;
                float: left;
                object-fit: cover;
            }

            .common-letter-wrapper {
                float: left;
                width: 100px;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: #eac922;

                .common-letter {
                    font-size: 2em;
                    font-weight: bold;
                    text-transform: capitalize;
                    color: $white;
                }
            }

            .pro-perm-content {
                float: left;
                width: calc(100% - 100px);
                padding: 0.4em 0.8em;
                padding-top: 1.3em;
                overflow: hidden;
                height: 100%;

                .switch-wrapper {
                    text-align: right;
                    height: 16px;
                }

                .title {
                    font-size: 1.2em;
                    padding-bottom: 0.3em;
                    color: $primary-font-color;
                }

                .pro-perm-email {
                    font-size: 0.76em;
                    color: $dark-grey-color;
                }

                .pro-perm-role {
                    color: $dark-grey-color;
                    font-size: 0.8em;
                    padding-top: 1.08em;
                    @include font-bold;                    
                }
            }
        }

        .pro-perm-edit {
            position: absolute;
            top: 0;
            right: 0;
            color: #b9b9b9;
            width: 34px;
            height: 34px;
            line-height: 34px;
            text-align: center;
            transition: all 0.2s linear;

            &:hover {
                background-color: #efefef;
                border-radius: 4px;
            }

            .edit-icon {
                height: 14px;
                width: 14px;
            }
        }
    }

    .pro-perm-form-wrapper {
        width: 40rem;
        padding: 2rem 8rem;

        @media screen and (max-width : #{$small-screen-up}) {
            width: 22rem;
            margin: 0 auto;
            padding: 2rem;
        }

        .pro-perm-form-role {
            text-align: left;
            height: 40px;
            width: 100%;
            border-radius: .3rem;
            font-size: 1em;
            padding: 0 1em;
            background-color: #f7f7f7;
            color: #404040;
            box-shadow: 0 0 1px 0 rgba(0,0,0,.16);

            .label {
                font-size: 0.9em;
                height: 1em;
                color: $primary-font-color;
                padding: 0 2px;
                float: left;
            }

            .value {
                padding-top: 10px;
            }
        }

        .pro-perm-form-del {
            text-align: center;

            .pro-perm-form-del-text {
                display: inline-block;
                text-decoration: underline;
                color: $primary-font-color;
                font-size: 0.96em;
                padding-top: 1.8rem;
                cursor: pointer;
            }
        }
    }

    .empty-page-wrapper {
        .btn {
            max-width: 32em;
            margin: 1.4em 0;
        }
    }
}
